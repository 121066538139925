import { Content as ContentType } from "../../types/contents";

export interface initialValuesI {
  path: string;
  content: string;
  metaTitle: string;
  metaKeywords: string;
  metaDescription: string;
  languageCode?: string;
}

export interface initialValuesEditI {
  id: string;
  path: string;
  content: string;
  metaTitle: string;
  metaKeywords: string;
  metaDescription: string;
  languageCode?: string;
}

export const initialValues = (): initialValuesI => ({
  path: "",
  content: "",
  metaTitle: "",
  metaKeywords: "",
  metaDescription: "",
});

export const initialValuesEdit = (content: ContentType | null): initialValuesEditI => ({
  id: content?._id ?? "",
  path: content?.path ?? "",
  content: content?.content ?? "",
  metaTitle: content?.metaTitle ?? "",
  metaKeywords: content?.metaKeywords ?? "",
  metaDescription: content?.metaDescription ?? "",
});
