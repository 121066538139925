import { createSlice } from "@reduxjs/toolkit";
import { IStoreStatuses, STATUS_ENUM } from "../../types/statuses";
import { StatusGenerator } from "../../utils/StatusGenerator";
import { createProjectThunk, editProjectThunk, getMyProjectsThunk, getProjectByIdThunk } from "./thunks";
import { LoadingResultsT } from "../../helpers/constants";
import { Project } from "../../types/post";

interface IState {
  projects: Project[] | null;
  selectedProject: Project | null;
  loading: LoadingResultsT.IDLE | LoadingResultsT.PENDING | LoadingResultsT.SUCCEEDED | LoadingResultsT.FAILED;
  statuses: IStoreStatuses;
}
const initialState: IState = {
  projects: [],
  selectedProject: null,
  loading: LoadingResultsT.IDLE,
  statuses: StatusGenerator.generateStatuses([
    getMyProjectsThunk.typePrefix,
    createProjectThunk.typePrefix,
    getProjectByIdThunk.typePrefix,
    editProjectThunk.typePrefix,
  ]),
};
const projectSlice = createSlice({
  name: "project",
  initialState: initialState,
  reducers: {
    reset: () => initialState,
    resetStatus: (state) => {
      state.statuses = initialState.statuses;
    },
    setProjects: (state, action) => {
      state.projects = action.payload.data ?? null;
    },
    setSelectedProject: (state, action) => {
      state.selectedProject = action.payload.data ?? null;
    },
    resetSelectedProject: (state) => {
      state.selectedProject = initialState.selectedProject;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(createProjectThunk.pending, (state) => {
        state.statuses[createProjectThunk.typePrefix] = StatusGenerator.setStatus(STATUS_ENUM.PENDING);
        state.loading = LoadingResultsT.PENDING;
      })
      .addCase(createProjectThunk.rejected, (state, { payload }) => {
        state.statuses[createProjectThunk.typePrefix] = StatusGenerator.setStatus(
          STATUS_ENUM.REJECTED,
          payload as string
        );
        state.loading = LoadingResultsT.FAILED;
      })
      .addCase(createProjectThunk.fulfilled, (state, { payload }) => {
        state.statuses[createProjectThunk.typePrefix] = StatusGenerator.setStatus(STATUS_ENUM.FULFILLED);
        state.selectedProject = payload;
        state.loading = LoadingResultsT.SUCCEEDED;
      })
      .addCase(getMyProjectsThunk.pending, (state) => {
        state.statuses[getMyProjectsThunk.typePrefix] = StatusGenerator.setStatus(STATUS_ENUM.PENDING);
        state.loading = LoadingResultsT.IDLE;
      })
      .addCase(getMyProjectsThunk.rejected, (state, { error }) => {
        state.statuses[getMyProjectsThunk.typePrefix] = StatusGenerator.setStatus(STATUS_ENUM.REJECTED, error.message);
      })
      .addCase(getMyProjectsThunk.fulfilled, (state, { payload }) => {
        state.statuses[getMyProjectsThunk.typePrefix] = StatusGenerator.setStatus(STATUS_ENUM.FULFILLED);
        state.projects = payload;
      })
      .addCase(getProjectByIdThunk.pending, (state) => {
        state.statuses[getProjectByIdThunk.typePrefix] = StatusGenerator.setStatus(STATUS_ENUM.PENDING);
      })
      .addCase(getProjectByIdThunk.rejected, (state, { error }) => {
        state.statuses[getProjectByIdThunk.typePrefix] = StatusGenerator.setStatus(STATUS_ENUM.REJECTED, error.message);
      })
      .addCase(getProjectByIdThunk.fulfilled, (state, { payload }) => {
        state.statuses[getProjectByIdThunk.typePrefix] = StatusGenerator.setStatus(STATUS_ENUM.FULFILLED);
        state.selectedProject = payload;
      })
      .addCase(editProjectThunk.pending, (state) => {
        state.statuses[editProjectThunk.typePrefix] = StatusGenerator.setStatus(STATUS_ENUM.PENDING);
        state.loading = LoadingResultsT.PENDING;
      })
      .addCase(editProjectThunk.rejected, (state, { payload }) => {
        state.statuses[editProjectThunk.typePrefix] = StatusGenerator.setStatus(
          STATUS_ENUM.REJECTED,
          payload as string
        );
        state.loading = LoadingResultsT.FAILED;
      })
      .addCase(editProjectThunk.fulfilled, (state, { payload }) => {
        state.statuses[editProjectThunk.typePrefix] = StatusGenerator.setStatus(STATUS_ENUM.FULFILLED);
        state.loading = LoadingResultsT.SUCCEEDED;
        state.selectedProject = payload?.data;
      }),
});

export default projectSlice;
