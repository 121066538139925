import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../config/api";
import { API_ROUTES } from "../../config/apiRoutes";
import { CreateProjectQuery, Project } from "../../types/post";
import { initialValuesEditI } from "../../pages/Project/config";

/**
 * @description Dispatch this thunk to create  project
 * @param {Project} bodyData - Object containing information for creating new project
 */
export const createProjectThunk = createAsyncThunk<Project, CreateProjectQuery>(
  "projects/createProjectThunk",
  async (bodyData, { rejectWithValue }) => {
    try {
      const { data } = await API.post(API_ROUTES.PROJECTS.CREATE, { ...bodyData });

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.error);
    }
  }
);

/**
 * @description Dispatch this thunk to get list of projects
 */
export const getMyProjectsThunk = createAsyncThunk("projects/getMyProjectsThunk", async (_, { rejectWithValue }) => {
  try {
    const { data } = await API.get(API_ROUTES.PROJECTS.ALL_PROJECTS());

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

/**
 * @description Dispatch this thunk to get project by id
 * @param {string} id - String id to find current project
 */
export const getProjectByIdThunk = createAsyncThunk<Project, string>(
  "projects/getProjectByIdThunk",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await API.get(API_ROUTES.PROJECTS.GET_BY_ID(id));

      return data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

/**
 * @description Dispatch this thunk to edit project by id
 * @param {Project} values - an Object containing information for updating project
 */

export const editProjectThunk = createAsyncThunk(
  "projects/editProjectThunk",
  async ({ id, ...values }: initialValuesEditI, { rejectWithValue }) => {
    try {
      const project = await API.put(API_ROUTES.PROJECTS.UPDATE_PROJECT(id), values);

      return project;
    } catch (error: any) {
      return rejectWithValue(error.response.data.error);
    }
  }
);
