import { ValidateProjectMessages } from "../../helpers/constants";
import { initialValuesI } from "./config";

export interface ValidationErrors {
  name?: string;
  path?: string;
}

export const validateProjectForm = (values: initialValuesI): ValidationErrors => {
  const errors: ValidationErrors = {};

  if (!values.name) {
    errors.name = ValidateProjectMessages.errorProjectNameRequired;
  } else if (values.name.length < 3) {
    errors.name = ValidateProjectMessages.errorProjectNameLength;
  }

  if (!values.path) {
    errors.path = ValidateProjectMessages.errorProjectPathRequired;
  } else if (values.path.length < 3) {
    errors.path = ValidateProjectMessages.errorProjectPathLength;
  }

  return errors;
};
