import styled from "@emotion/styled";

export const StyledTitle = styled("h1")(() => ({
  fontWeight: 700,
  fontSize: 42,
  color: "#424141",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "20%",
}));
