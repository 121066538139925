/* eslint-disable no-useless-escape */
import React, { useState } from "react";
import {
  InputForm,
  InputWrapper,
  ClosedEyeButton,
  OpenEyeButton,
  ErrorText,
} from "./style";
import { InputForm as Props } from "../../types/members";
import { ErrorsValidation } from "../FormNewMembers/ErrorValitation";

const InputFormNewMembers: React.FC<Props> = ({
  value,
  setFunction,
  placeholder,
  type,
  error,
  name,
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  function isEmail(
    isPasswordVisible: boolean,
    setIsPasswordVisible: React.Dispatch<React.SetStateAction<boolean>>
  ) {
    return (
      <>
        {isPasswordVisible ? (
          <OpenEyeButton onClick={() => setIsPasswordVisible(false)} />
        ) : (
          <ClosedEyeButton onClick={() => setIsPasswordVisible(true)} />
        )}
      </>
    );
  }

  function getPattern(placeholder: string) {
    if (placeholder === "First Name" || placeholder === "Last Name") {
      return "[a-zA-Z0-9]{2,20}$";
    }
    if (placeholder === "Password" || placeholder === "Confirm Password") {
      return `(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{8,}`;
    }
    if (placeholder === "Email") {
      return `([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$`;
    } else {
      return "";
    }
  }
  return (
    <InputWrapper>
      <InputForm
        pattern={getPattern(placeholder)}
        name={name}
        type={
          (placeholder === "Password" || placeholder === "Confirm Password") &&
          isPasswordVisible
            ? "text"
            : type
        }
        placeholder={placeholder}
        value={value}
        onChange={(e) => setFunction(e.target.value)}
      />
      {placeholder === "Password" || placeholder === "Confirm Password" ? (
        isEmail(isPasswordVisible, setIsPasswordVisible)
      ) : (
        <></>
      )}
      {error ? <ErrorText>{ErrorsValidation(name)}</ErrorText> : <></>}
    </InputWrapper>
  );
};
export default React.memo(InputFormNewMembers);
