/**
 * @description Object containing all request endpoints
 */
export const API_ROUTES = {
  AUTH: {
    LOGIN: "auth/login/",
    SIGN_UP: "auth/admin-register/",
    CHANGE_PASSWORD: "auth/change-password/",
    CHANGE_PASSWORD_BY_ADMIN: `auth/change-password-by-admin/`,
    RESET_PASSWORD: "auth/reset-password/",
  },
  USERS: {
    CREATE: "users/create/",
    CREATE_MODERATOR: "users/moderator-create/",
    GET_ADMIN: "users/admin/",
    GET_LIST: (limit: number, page: number) => `users/?page=${page}&limit=${limit}`,
    DELETE: (id: string) => `users/delete/${id}/`,
    GET_BY_ID: (id: string) => `users/${id}/`,
    UPDATE: (id: string) => `users/${id}/`,
  },
  POSTS: {
    CREATE: "posts/create/",
    DELETE: (id: string) => `posts/delete/${id}/`,
    ALL_POSTS: (limit: number = 8, page: number = 1, language = "") =>
      `posts-admin/?page=${page}&limit=${limit}&language=${language}`,
    GET_BY_ID: (id: string) => `post/${id}/`,
    UPDATE_POST: (id: string) => `posts/update/${id}/`,
    POSTS_URL: () => `posts/blog/url/`,
  },
  ONE_TIME_LINKS: {
    GENERATE: "one-time-link/generate/",
    VALIDATE: "one-time-link/validate/",
    DELETE: (token: string) => `one-time-link/delete/${token}`,
  },
  PROJECTS: {
    CREATE: "projects/",
    ALL_PROJECTS: () => "projects/my/",
    GET_BY_ID: (id: string) => `projects/${id}/`,
    UPDATE_PROJECT: (id: string) => `projects/${id}/`,
  },
  CONTENTS: {
    ALL_CONTENTS_BY_PROJECTS: (id: string) => `projects/${id}/content/`,
    CREATE: (id: string) => `projects/${id}/content/`,
    GET_BY_ID: (id: string) => `contents/${id}/`,
    UPDATE_CONTENT: (id: string) => `contents/${id}/`,
  },
  CONTENTS_TRANSLATION: {
    GET_BY_ID: (contentId: string, languageCode: string) => `contents/${contentId}/translations/${languageCode}/`,
    CREATE: (contentId: string, languageCode: string) => `contents/${contentId}/translations/${languageCode}/`,
    UPDATE_CONTENT: (contentId: string, languageCode: string) => `contents/${contentId}/translations/${languageCode}/`,
  },
};
