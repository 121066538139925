import { styled } from "@mui/material";
export const MainWrapper = styled("div")(() => ({}));
export const TitleForm = styled("p")(() => ({
  fontFamily: "Nunito, sans-serif",
  color: "#2196F3",
  textTransform: "uppercase",
  fontWeight: 700,
  margin: "0 0 30px 0",
}));
export const FormWrapper = styled("div")(() => ({
  width: "100%",
  height: "fit-content",
}));
export const FotoSection = styled("div")(() => ({
  width: "100%",
  height: "fit-content",
  padding: "6px 0",
  display: "flex",
  alignItems: "center",
}));
export const NoFotoUser = styled("div")(() => ({
  width: 85,
  height: 85,
  borderRadius: "50%",
  background: "#C4C4C4",
  marginRight: 25,
}));
export const ButtonAddImage = styled("div")(() => ({
  width: 155,
  height: 50,
  alignItems: "center",
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
  background: "linear-gradient(93.62deg, #69BDFF 3.14%, #2196F3 87.37%)",
  borderRadius: 12,
}));
export const InputFormSection = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  marginTop: 20,
}));
export const RoleSection = styled("div")(() => ({
  width: "100%",
  display: "grid",
  justifyContent: "space-between",
  gridTemplateColumns: "47% 47%",
}));
export const RoleButton = styled("div")(({ theme, className }) => ({
  width: "100%",
  height: 45,
  background: className?.includes("active") ? "#D2EBFF" : "white",
  borderRadius: 50,
  alignItems: "center",
  cursor: "pointer",
  border: "1px solid #D2EBFF",
  display: "flex",
  justifyContent: "center",
  marginBottom: 15,
}));

export const RoleButtonText = styled("p")(() => ({
  fontFamily: "Montserrat",
  fontStyle: "normal",
  width: "fit-content",
  fontWeight: 700,
  fontSize: 16,
  lineHeight: "160%",
  color: "#2196F3",
}));
export const SaveButtonWrapper = styled("div")(({ theme, className }) => ({
  width: "100%",
  display: "grid",
  justifyContent: "space-between",
  gridTemplateColumns: "47% 47%",
  marginTop: 20,
}));
export const SaveButton = styled("button")(({ theme, className }) => ({
  height: 50,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: "#FEB25B",
  cursor: "pointer",
  border: "none",
  borderRadius: 12,
  ":disabled": {
    pointerEvents: "none",
    background: "#858585",
  },
}));
export const SaveButtonText = styled("p")(() => ({
  fontFamily: "Nunito, sans-serif",
  fontStyle: "normal",
  width: "fit-content",
  color: "white",
  fontWeight: 700,
  fontSize: 17,
  textTransform: "uppercase",
  textAlign: "center",
}));
export const InputWrapper = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  marginBottom: 15,
}));
