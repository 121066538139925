import * as React from "react";
import { Props } from "./config";
import { Modal, Box } from "@mui/material";
import {
  validatePassword,
  ErrorsValidation,
} from "../FormNewMembers/ErrorValitation";
import Notification from "../../utils/Notification";
import {
  style,
  StyledButtonContainer,
  StyledFormButton,
  TextFieldWrapper,
  StyledText,
  StyledFormicError,
} from "./styles";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { changePasswordByAdminThunk } from "../../redux/users/thunks";
import usersSlice from "../../redux/users/slice";

const ResetPasswordUserModal = (props: Props) => {
  const [notificationIsOpen, setNotification] = React.useState(false);
  const dispatch = useAppDispatch();
  const [validationError, setValidationError] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  React.useEffect(() => {
    if (!validatePassword(password)) {
      setValidationError(ErrorsValidation("password"));
    } else setValidationError("");
  }, [password]);

  const changePassword = () => {
    dispatch(
      changePasswordByAdminThunk({
        id: props.selectedUserId,
        password: password,
      })
    );
    setNotification(true);
    props.setOpenModal(false);
  };

  const hadleCloseNotification = () => {
    setNotification(false);
    dispatch(usersSlice.actions.resetStatus());
    props.setOpenModal(false);
  };

  function handlerSetPassword(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    setPassword(e.target.value);
  }

  return (
    <div>
      <Modal
        open={props.openModal}
        onClose={() => hadleCloseNotification()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <StyledText id="modal-modal-title">Reset Password</StyledText>
          <Box>
            <TextFieldWrapper
              margin="normal"
              required
              fullWidth
              id="password"
              type="text"
              label="Reset Password"
              name="password"
              autoFocus
              onChange={(e) => handlerSetPassword(e)}
            />
            {validationError && (
              <StyledFormicError>{validationError}</StyledFormicError>
            )}
            <StyledButtonContainer>
              <StyledFormButton
                type="button"
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={true}
              >
                {/* Generate new password */}
                Under development...
              </StyledFormButton>
              <StyledFormButton
                type="submit"
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={!validatePassword(password) || password === ""}
                onClick={() => changePassword()}
              >
                change
              </StyledFormButton>
            </StyledButtonContainer>
          </Box>
        </Box>
      </Modal>
      {
        <Notification
          isOpen={notificationIsOpen}
          onClose={hadleCloseNotification}
        />
      }
    </div>
  );
};

export default ResetPasswordUserModal;
