export enum ICON_TITLE {
  EDIT_POST = "Edit post",
  ADD_POST = "Add post",
  CHANGE_POST_STATUS = "Change status",
}

export enum BTN_TITLE {
  SAVE = "Save",
  CANCEL = "Cancel",
  CREATE = "Create new",
  EDIT = "Edit",
}

export enum INPUT_LABEL {
  TITLE = "Title",
  NAME = "Name",
  STATUS = "Status",
  SHORT_DESCRIPTION = "Short Description",
  TOPIC = "Topic",
  IMAGE = "Image",
  TAGS = "Tags",
  IMAGE_ALT = "Image Alt",
  SEO_TITLE = "Seo Title",
  SEO_KEYWORD = "Seo Keyword",
  SEO_DESCRIPTION = "Seo Description",
  META_TITLE = "Meta Title",
  META_KEYWORD = "Meta Keywords",
  META_DESCRIPTION = "Meta Description",
  URL = "Url",
  PATH = "Path",
  ADDTAG = "Add tag",
  SIMILAR_ARTICLES = "Similar Articles",
}

export enum TOPIC_ACTIONS {
  EDIT = "edit topic",
  ADD = "create new topic",
  URL_EDIT = "edit",
  URL_ADD = "add",
  CONTENTS = "Contents",
  TOPICS = "Topics",
  PROJECTS = "Projects",
  MEMBERS = "Members",
}
export enum TOPICS_BUTTON {
  CONTENTS = "/contents",
  MEMBERS = "/members",
  TOPICS = "/topics",
  PROJECTS = "/projects",
}
export enum STATUS_SELECT {
  ALL = "All",
  PUBLISHED = "Published",
  DRAFT = "Draft",
  ARCHIVED = "Archived",
}
export enum STATUS_TYPES_ENUM {
  PUBLISHED = "PUBLISHED",
  DRAFT = "DRAFT",
  ARCHIVED = "ARCHIVED",
}

export enum LANGUAGES_TYPES_ENUM {
  ENGLISH = "en",
  UKRAINIAN = "uk",
  SPANISH = "es",
  POLISH = "pl",
  GERMAN = "de",
  PORTUGUESE = "pt",
  FRENCH = "fr",
  GREEK = "el",
  BULGARIAN = "bg",
  BOSNIAN = "bs",
  CZECH = "cs",
  DANISH = "da",
  ESTONIAN = "et",
  FINNISH = "fi",
  CROATIAN = "hr",
  HUNGARIAN = "hu",
  ITALIAN = "it",
  JAPANESE = "ja",
  GEORGIAN = "ka",
  KAZAKH = "kk",
  KOREAN = "ko",
  LITHUANIAN = "lt",
  LATVIAN = "lv",
  MACEDONIAN = "mk",
  MALAY = "ms",
  DUTCH = "nl",
  NORWEGIAN = "no",
  ROMANIAN = "ro",
  SLOVAK = "sk",
  SLOVENIAN = "sl",
  ALBANIAN = "sq",
  SERBIAN = "sr",
  SWEDISH = "sv",
  THAI = "th",
  TURKISH = "tr",
  CHINESE = "zh",
  VIETNAMESE = "vi",
  INDONESIAN = "id",
}

export enum LANGUAGES_FILTER_ENUM {
  All = "All Languages",
  ENGLISH = "English",
  UKRAINIAN = "Ukrainian",
  SPANISH = "Spanish",
  POLISH = "Polish",
  GERMAN = "German",
  PORTUGUESE = "Portuguese",
  FRENCH = "French",
  GREEK = "Greek",
}

export const BASE_URL = process.env.REACT_APP_URL;

export enum LoadingResultsT {
  IDLE = "idle",
  PENDING = "pending",
  SUCCEEDED = "succeeded",
  FAILED = "failed",
}

export enum Messages {
  errorPassword = "Sorry, failed to change password",
  successPassword = "Password changed successfully",
  errorEditPost = "You cannot change status to Published. Fill please all fields first",
  successEditPost = "Post changed successfully",
  successCreatePost = "Post created successfully",
  successCreateProject = "Project created successfully",
  successEditProject = "Project changed successfully",
  successCreateContent = "Content created successfully",
  successEditContent = "Content changed successfully",
  successCreateContentTranslation = "Content translation created successfully",
  successEditContentTranslation = "Content translation changed successfully",
}

export enum UrlStyles {
  ITEM_HEIGHT = 48,
  ITEM_PADDING_TOP = 8,
}

export enum RoleUsers {
  ADMIN = "Administrator",
  USER = "User",
  MODERATOR = "Moderator",
}
export enum LongMenuConfig {
  EDIT = "Edit",
  RESET_PASSWORD = "Reset Password",
  DEACTIVATE = "Deactivate",
}

export enum PROJECT_ACTIONS {
  EDIT = "edit project",
  ADD = "create new project",
  URL_EDIT = "edit",
}

export enum CONTENT_ACTIONS {
  EDIT = "edit content",
  ADD = "create new content",
  URL_EDIT = "edit",
}

export enum ValidateProjectMessages {
  errorProjectNameRequired = "The name is required!",
  errorProjectNameLength = "Minimum 3 characters!",
  errorProjectPathRequired = "The path is required!",
  errorProjectPathLength = "Minimum 3 characters!",
}

export enum ValidateContentMessages {
  errorContentPathRequired = "The path is required!",
  errorContentPathLength = "Minimum 3 characters!",
  errorContentRequired = "The content is required!",
  errorContentMetaTitleRequired = "The metaTitle is required!",
  errorContentMetaDescriptionRequired = "The metaDescription is required!",
  errorContentMetaKeywordsRequired = "The metaKeywords is required!",
}
