import * as React from "react";
import { useEffect } from "react";
import {
  Box,
  Grid,
  Tooltip,
  Pagination,
  PaginationItem,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { useAppSelector } from "../../hooks/useAppSelector";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { getAllPostsThunk } from "../../redux/posts/thunks";
import { postsSelector } from "../../redux/posts/selectors";
import { AdminRoutes } from "../../config/routes";
import { Post } from "../../types/post";
import { BTN_TITLE, ICON_TITLE, LANGUAGES_FILTER_ENUM } from "../../helpers/constants";
import AdminMainButton from "../../components/AdminMainButton";
import {
  LinkWrapper,
  StyledStatusButton,
  StyledTable,
  StyledTBody,
  StyledTData,
  StyledTRow,
  StyledTitleNoTopics,
  NoTopicsWrapper,
  ImageBackground,
  NoTopicsTitleWrapper,
  StyledTDataUrl,
  StyledTDataName,
  StyledHead,
  StyledNameBox,
  StyledStatusBox,
  StyledUrlBox,
  StyledDataBox,
  StyledActionBox,
  StyledTDataStatus,
  StyledTDataDate,
  StyleName,
  StyledUrl,
  StyledLine,
  ActionWrapper,
  GridWrapper,
  BoxWrapper,
  StyledGridSelect,
  TopicHeader,
} from "./styles";
import ActionTopicButton from "../../components/ActionTopicButton";
import { format } from "date-fns";
import Container from "../../components/Container";
import { FlagImg } from "../Topic/styles";
import { LANGUAGES_TYPES_ENUM } from "../../helpers/constants";
import { userSelector } from "../../redux/auth/selectors";

const Topics: React.FC = () => {
  const dispatch = useAppDispatch();
  const posts = useAppSelector(postsSelector);

  const [languageFilter, setLanguageFilter] = React.useState("All Languages");
  const [languageFetch, setLanguageFetch] = React.useState("");
  const [page, setPage] = React.useState(posts?.page);
  useEffect(() => {
    languageFilter === "All Languages" && setLanguageFetch("");
    languageFilter === "English" && setLanguageFetch(LANGUAGES_TYPES_ENUM.ENGLISH);
    languageFilter === "Ukrainian" && setLanguageFetch(LANGUAGES_TYPES_ENUM.UKRAINIAN);
    languageFilter === "Spanish" && setLanguageFetch(LANGUAGES_TYPES_ENUM.SPANISH);
    languageFilter === "Polish" && setLanguageFetch(LANGUAGES_TYPES_ENUM.POLISH);
    languageFilter === "German" && setLanguageFetch(LANGUAGES_TYPES_ENUM.GERMAN);
    languageFilter === "Portuguese" && setLanguageFetch(LANGUAGES_TYPES_ENUM.PORTUGUESE);
    languageFilter === "French" && setLanguageFetch(LANGUAGES_TYPES_ENUM.FRENCH);
    languageFilter === "Greek" && setLanguageFetch(LANGUAGES_TYPES_ENUM.GREEK);
    setPage(1);
  }, [languageFilter]);

  useEffect(() => {
    dispatch(getAllPostsThunk({ limit: 8, page: page, language: languageFetch }));
  }, [dispatch, languageFetch]);

  const handleSetFilter = (event: SelectChangeEvent) => {
    setLanguageFilter(event.target.value as string);
  };

  const renderPost = (arr: any) => {
    return arr?.map((post: Post) => (
      <>
        <StyledTRow key={post._id}>
          <StyledTDataName>
            <StyleName>{post.title}</StyleName>
          </StyledTDataName>
          <StyledTDataStatus>
            <StyledStatusButton
              style={
                post.status === "DRAFT"
                  ? { background: "#EBF6FF", color: "#2196F3" }
                  : post.status === "PUBLISHED"
                  ? { background: "#2196F3" }
                  : { background: "#CAC7C7" }
              }
            >
              {post.status}
            </StyledStatusButton>
          </StyledTDataStatus>
          <StyledTDataUrl>
            <StyledUrl>{post.url}</StyledUrl>
          </StyledTDataUrl>
          <StyledTDataDate>{format(new Date(`${post.createdAt}`), "dd.MM.yyyy")}</StyledTDataDate>
          <StyledTData>
            <LinkWrapper to={`${AdminRoutes.Topic}/edit/${post._id}`}>
              <Tooltip title={ICON_TITLE.EDIT_POST} arrow>
                <ActionTopicButton text={BTN_TITLE.EDIT} />
              </Tooltip>
            </LinkWrapper>
          </StyledTData>
        </StyledTRow>
        <StyledLine></StyledLine>
      </>
    ));
  };

  return (
    <Container>
      {posts?.docs.length ? (
        <BoxWrapper>
          <StyledGridSelect item xs={2}>
            <TopicHeader>
              <FormControl
                size="small"
                sx={{
                  minWidth: "fit-content",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "& .Mui-focused": {
                    backgroundColor: "transparent",
                    color: "black",
                    fontWeight: "600",
                    "& svg": {
                      display: "block",
                    },
                  },
                }}
              >
                <Select
                  IconComponent={KeyboardArrowDownIcon}
                  sx={{
                    borderRadius: "30px",
                    padding: 0,
                    backgroundColor: "#CAC7C7",
                    color: "white",
                    fontWeight: "600",
                    "& svg": {
                      display: "none",
                    },
                  }}
                  id="lang-simple-select-label"
                  value={languageFilter}
                  label="language"
                  name="language"
                  onChange={handleSetFilter}
                >
                  <MenuItem value={LANGUAGES_FILTER_ENUM.All}>{LANGUAGES_FILTER_ENUM.All}</MenuItem>
                  <MenuItem value={LANGUAGES_FILTER_ENUM.ENGLISH}>
                    <FlagImg width="20px" height="12px" src="/images/flag/flag-en.svg" alt="flag of Greate Britain" />
                    {LANGUAGES_FILTER_ENUM.ENGLISH}
                  </MenuItem>
                  <MenuItem value={LANGUAGES_FILTER_ENUM.UKRAINIAN}>
                    <FlagImg width="20px" height="12px" src="/images/flag/flag-uk.svg" alt="flag of Ukraine" />
                    {LANGUAGES_FILTER_ENUM.UKRAINIAN}
                  </MenuItem>
                  <MenuItem value={LANGUAGES_FILTER_ENUM.GERMAN}>
                    <FlagImg width="20px" height="12px" src="/images/flag/flag-de.svg" alt="flag of Germany" />
                    {LANGUAGES_FILTER_ENUM.GERMAN}
                  </MenuItem>
                  <MenuItem value={LANGUAGES_FILTER_ENUM.SPANISH}>
                    <FlagImg width="20px" height="12px" src="/images/flag/flag-es.svg" alt="flag of Spain" />
                    {LANGUAGES_FILTER_ENUM.SPANISH}
                  </MenuItem>
                  <MenuItem value={LANGUAGES_FILTER_ENUM.POLISH}>
                    <FlagImg width="20px" height="12px" src="/images/flag/flag-pl.svg" alt="flag of Poland" />
                    {LANGUAGES_FILTER_ENUM.POLISH}
                  </MenuItem>
                  <MenuItem value={LANGUAGES_FILTER_ENUM.PORTUGUESE}>
                    <FlagImg width="20px" height="12px" src="/images/flag/flag-pt.svg" alt="flag of Portugal" />
                    {LANGUAGES_FILTER_ENUM.PORTUGUESE}
                  </MenuItem>
                  <MenuItem value={LANGUAGES_FILTER_ENUM.FRENCH}>
                    <FlagImg width="20px" height="12px" src="/images/flag/flag-fr.svg" alt="flag of France" />
                    {LANGUAGES_FILTER_ENUM.FRENCH}
                  </MenuItem>
                  <MenuItem value={LANGUAGES_FILTER_ENUM.GREEK}>
                    <FlagImg width="20px" height="12px" src="/images/flag/flag-gr.svg" alt="flag of Greece" />
                    {LANGUAGES_FILTER_ENUM.GREEK}
                  </MenuItem>
                </Select>
              </FormControl>
              <GridWrapper container justifyContent="space-between">
                <Grid item></Grid>
                <Grid item>
                  <LinkWrapper to={`${AdminRoutes.Topic}/add`}>
                    <AdminMainButton text={BTN_TITLE.CREATE} />
                  </LinkWrapper>
                </Grid>
              </GridWrapper>
            </TopicHeader>
          </StyledGridSelect>
          <Box sx={{ maxWidth: "100%" }}>
            <Grid container xs={12}>
              <StyledHead>
                <StyledNameBox>Name</StyledNameBox>
                <StyledStatusBox>Status</StyledStatusBox>
                <StyledUrlBox>Url</StyledUrlBox>
                <StyledDataBox>Date</StyledDataBox>
                <StyledActionBox>
                  <ActionWrapper>Action</ActionWrapper>
                </StyledActionBox>
              </StyledHead>
              <StyledTable>
                <StyledTBody>{renderPost(posts?.docs)}</StyledTBody>
              </StyledTable>
            </Grid>
          </Box>
          <Pagination
            hideNextButton
            hidePrevButton
            defaultPage={posts?.page}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              "li button": {
                fontFamily: `"Montserrat", sans-serif`,
                fontWeight: 600,
                color: "#717384",
              },
            }}
            page={posts?.page}
            count={posts?.totalPages}
            onChange={(event: React.ChangeEvent<unknown>, page: number) => {
              dispatch(
                getAllPostsThunk({
                  limit: posts?.limit,
                  page: page,
                  status: "All",
                  language: languageFetch,
                })
              );
            }}
          />
        </BoxWrapper>
      ) : (
        <NoTopicsWrapper>
          <ImageBackground></ImageBackground>
          <NoTopicsTitleWrapper>
            <StyledTitleNoTopics>Have no topics yet</StyledTitleNoTopics>
            <LinkWrapper to={`${AdminRoutes.Topic}/add`}>
              <AdminMainButton text={BTN_TITLE.CREATE} />
            </LinkWrapper>
          </NoTopicsTitleWrapper>
        </NoTopicsWrapper>
      )}
    </Container>
  );
};

export default Topics;
