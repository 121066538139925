import React from "react";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import usersSlice from "../../redux/users/slice";
import {
  MainWrapper,
  ModalWrapper,
  ContentWrapper,
  Title,
  ModalButton,
  ButtonsWrapper,
} from "./styles";

const ModalMembers: React.FC = () => {
  const dispatch = useAppDispatch();
  const handleFormMemberModalContinue = () => {
    dispatch(usersSlice.actions.modalUsers(false));
  };
  const handleFormMemberModalСlear = () => {
    dispatch(usersSlice.actions.modalUsers(false));
    dispatch(usersSlice.actions.formCleaner(true));
  };
  return (
    <MainWrapper>
      <ModalWrapper>
        <ContentWrapper>
          <Title>
            You have not finished adding a new member. Do you want to continue?
          </Title>
          <ButtonsWrapper>
            <ModalButton
              className={"blue"}
              onClick={() => handleFormMemberModalСlear()}
            >
              NO
            </ModalButton>
            <ModalButton onClick={() => handleFormMemberModalContinue()}>
              YES
            </ModalButton>
          </ButtonsWrapper>
        </ContentWrapper>
      </ModalWrapper>
    </MainWrapper>
  );
};

export default ModalMembers;
