import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { useAppSelector } from "../hooks/useAppSelector";
import { authStatusSelector } from "../redux/auth/selectors";
import { userStatusSelector } from "../redux/users/selectors";
import { postStatusSelector } from "../redux/posts/selectors";
import { Messages } from "../helpers/constants";
import { projectStatusSelector } from "../redux/projects/selectors";
import { contentStatusSelector } from "../redux/contents/selectors";

type NotificationProps = {
  isOpen?: boolean;
  onClose: () => void;
};

export const NotificationMessage = () => {
  const authStatuses = useAppSelector(authStatusSelector);
  const postStatuses = useAppSelector(postStatusSelector);
  const userStatuses = useAppSelector(userStatusSelector);
  const projectStatuses = useAppSelector(projectStatusSelector);
  const contentStatuses = useAppSelector(contentStatusSelector);
  return {
    errorMessage:
      postStatuses["posts/editPostThunk"]?.error ||
      postStatuses["posts/createPostThunk"]?.error ||
      authStatuses["auth/resetPasswordThunk"]?.error ||
      authStatuses["auth/changePasswordThunk"]?.error ||
      userStatuses["users/changePasswordByAdminThunk"]?.error ||
      projectStatuses["projects/createProjectThunk"]?.error ||
      projectStatuses["projects/editProjectThunk"]?.error ||
      contentStatuses["contents/createContentThunk"]?.error ||
      contentStatuses["contents/editContentThunk"]?.error ||
      contentStatuses["contents/createContentTranslationThunk"]?.error ||
      contentStatuses["contents/editContentTranslationThunk"]?.error,

    successMessage:
      postStatuses["posts/editPostThunk"]?.success ||
      postStatuses["posts/createPostThunk"]?.success ||
      authStatuses["auth/resetPasswordThunk"]?.success ||
      authStatuses["auth/changePasswordThunk"]?.success ||
      userStatuses["users/changePasswordByAdminThunk"]?.success ||
      projectStatuses["projects/createProjectThunk"]?.success ||
      projectStatuses["projects/editProjectThunk"]?.success ||
      contentStatuses["contents/createContentThunk"]?.success ||
      contentStatuses["contents/editContentThunk"]?.success ||
      contentStatuses["contents/createContentTranslationThunk"]?.success ||
      contentStatuses["contents/editContentTranslationThunk"]?.success,
  };
};

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Notification({ isOpen, onClose }: NotificationProps) {
  const authStatuses = useAppSelector(authStatusSelector);
  const postStatuses = useAppSelector(postStatusSelector);
  const userStatuses = useAppSelector(userStatusSelector);
  const projectStatuses = useAppSelector(projectStatusSelector);
  const contentStatuses = useAppSelector(contentStatusSelector);
  const errorMessage =
    postStatuses["posts/editPostThunk"]?.error ||
    postStatuses["posts/createPostThunk"]?.error ||
    authStatuses["auth/resetPasswordThunk"]?.error ||
    authStatuses["auth/changePasswordThunk"]?.error ||
    userStatuses["auth/changePasswordByAdminThunk"]?.error ||
    projectStatuses["projects/createProjectThunk"]?.error ||
    projectStatuses["projects/editProjectThunk"]?.error ||
    contentStatuses["contents/createContentThunk"]?.error ||
    contentStatuses["contents/editContentThunk"]?.error ||
    contentStatuses["contents/createContentTranslationThunk"]?.error ||
    contentStatuses["contents/editContentTranslationThunk"]?.error;

  let successMessage;

  if (postStatuses["posts/editPostThunk"]?.success) {
    successMessage = Messages.successEditPost;
  }
  if (postStatuses["posts/createPostThunk"]?.success) {
    successMessage = Messages.successCreatePost;
  }
  if (authStatuses["auth/resetPasswordThunk"]?.success) {
    successMessage = Messages.successPassword;
  }
  if (authStatuses["auth/changePasswordThunk"]?.success) {
    successMessage = Messages.successPassword;
  }
  if (userStatuses["auth/changePasswordByAdminThunk"]?.success) {
    successMessage = Messages.successPassword;
  }
  if (projectStatuses["projects/createProjectThunk"]?.success) {
    successMessage = Messages.successCreateProject;
  }
  if (projectStatuses["projects/editProjectThunk"]?.success) {
    successMessage = Messages.successEditProject;
  }
  if (contentStatuses["contents/createContentThunk"]?.success) {
    successMessage = Messages.successCreateContent;
  }
  if (contentStatuses["contents/editContentThunk"]?.success) {
    successMessage = Messages.successEditContent;
  }
  if (contentStatuses["contents/createContentTranslationThunk"]?.success) {
    successMessage = Messages.successCreateContentTranslation;
  }
  if (contentStatuses["contents/editContentTranslationThunk"]?.success) {
    successMessage = Messages.successCreateContentTranslation;
  }

  const severity = errorMessage ? "error" : "success";
  const message = errorMessage || successMessage;
  const openOnSuccess = postStatuses["posts/createPostThunk"]?.success || postStatuses["posts/editPostThunk"]?.success;

  return message ? (
    <Snackbar
      open={isOpen || openOnSuccess}
      autoHideDuration={2000}
      onClose={onClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    >
      <Alert onClose={onClose} severity={severity} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  ) : null;
}

export default Notification;
