import * as React from "react";
import { useEffect } from "react";
import { Box, Grid, Tooltip, FormControl, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { useAppSelector } from "../../hooks/useAppSelector";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { AdminRoutes } from "../../config/routes";
import { BTN_TITLE, ICON_TITLE } from "../../helpers/constants";
import AdminMainButton from "../../components/AdminMainButton";
import {
  LinkWrapper,
  StyledTable,
  StyledTBody,
  StyledTData,
  StyledTRow,
  StyledTitleNoTopics,
  NoTopicsWrapper,
  ImageBackground,
  NoTopicsTitleWrapper,
  StyledTDataUrl,
  StyledTDataName,
  StyledHead,
  StyledNameBox,
  StyledUrlBox,
  StyledDataBox,
  StyledActionBox,
  StyledTDataDate,
  StyleName,
  StyledUrl,
  StyledLine,
  ActionWrapper,
  GridWrapper,
  BoxWrapper,
  StyledGridSelect,
  TopicHeader,
  StyledChooseProjectTitle,
  ProjectChooseWrapper,
} from "./styles";
import ActionTopicButton from "../../components/ActionTopicButton";
import { format } from "date-fns";
import Container from "../../components/Container";
import { projectsSelector } from "../../redux/projects/selectors";
import { getAllContentsByProjectThunk } from "../../redux/contents/thunks";
import { contentSelector } from "../../redux/contents/selectors";
import { Content } from "../../types/contents";
import { getMyProjectsThunk } from "../../redux/projects/thunks";
import contentSlice from "../../redux/contents/slice";

const Contents: React.FC = () => {
  const dispatch = useAppDispatch();
  const projects = useAppSelector(projectsSelector);
  const posts = useAppSelector(contentSelector);

  const [projectIdFilter, setProjectIdFilter] = React.useState("");

  useEffect(() => {
    dispatch(getMyProjectsThunk());
  }, [dispatch]);

  useEffect(() => {
    const storedProjectId = localStorage.getItem("selectedProjectId");

    if (storedProjectId) {
      setProjectIdFilter(storedProjectId);
    } else if (projects && projects.length > 0 && !projectIdFilter) {
      setProjectIdFilter(projects[0]._id);
    }
  }, [projects, projectIdFilter]);

  useEffect(() => {
    if (projectIdFilter) {
      dispatch(getAllContentsByProjectThunk({ id: projectIdFilter }));
    }
  }, [dispatch, projectIdFilter]);

  const handleSetFilter = (event: SelectChangeEvent) => {
    const selectedProjectId = event.target.value as string;
    setProjectIdFilter(selectedProjectId);
    localStorage.setItem("selectedProjectId", selectedProjectId);
  };

  const handleResetPath = () => {
    dispatch(contentSlice.actions.resetPath());
  };

  const renderContent = (arr: any) => {
    return arr?.map((content: Content) => (
      <>
        <StyledTRow key={content._id}>
          <StyledTDataUrl>
            <StyledUrl>{content.path}</StyledUrl>
          </StyledTDataUrl>
          <StyledTDataName>
            <StyleName>{content.metaTitle}</StyleName>
          </StyledTDataName>
          <StyledTDataDate>{format(new Date(`${content.createdAt}`), "dd.MM.yyyy")}</StyledTDataDate>
          <StyledTData>
            <LinkWrapper to={`${AdminRoutes.Contents}/edit/${content._id}`}>
              <Tooltip title={ICON_TITLE.EDIT_POST} arrow>
                <ActionTopicButton text={BTN_TITLE.EDIT} />
              </Tooltip>
            </LinkWrapper>
          </StyledTData>
        </StyledTRow>
        <StyledLine></StyledLine>
      </>
    ));
  };

  return (
    <Container>
      {projects && projects.length > 0 ? (
        <>
          <ProjectChooseWrapper>
            <StyledChooseProjectTitle>Choose a project</StyledChooseProjectTitle>
            <StyledGridSelect item xs={2}>
              <FormControl
                size="small"
                sx={{
                  minWidth: "fit-content",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "& .Mui-focused": {
                    backgroundColor: "transparent",
                    color: "black",
                    fontWeight: "600",
                    "& svg": {
                      display: "block",
                    },
                  },
                }}
              >
                <Select
                  IconComponent={KeyboardArrowDownIcon}
                  sx={{
                    borderRadius: "30px",
                    padding: 0,
                    backgroundColor: "#CAC7C7",
                    color: "white",
                    fontWeight: "600",
                    "& svg": {
                      display: "none",
                    },
                  }}
                  id="lang-simple-select-label"
                  value={projectIdFilter || projects?.[0]?._id}
                  label="projects"
                  name="projects"
                  onChange={handleSetFilter}
                >
                  {projects?.map((project) => (
                    <MenuItem key={project._id} value={project._id}>
                      {project.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </StyledGridSelect>
          </ProjectChooseWrapper>
          {posts && posts.length > 0 ? (
            <BoxWrapper>
              <StyledGridSelect item xs={2}>
                <TopicHeader>
                  <FormControl
                    size="small"
                    sx={{
                      minWidth: "fit-content",
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "& .Mui-focused": {
                        backgroundColor: "transparent",
                        color: "black",
                        fontWeight: "600",
                        "& svg": {
                          display: "block",
                        },
                      },
                    }}
                  ></FormControl>
                  <GridWrapper container justifyContent="space-between">
                    <Grid item></Grid>
                    <Grid item>
                      <LinkWrapper to={`${AdminRoutes.Contents}/add?projectId=${projectIdFilter}`}>
                        <AdminMainButton onClick={handleResetPath} text={BTN_TITLE.CREATE} />
                      </LinkWrapper>
                    </Grid>
                  </GridWrapper>
                </TopicHeader>
              </StyledGridSelect>
              <Box sx={{ maxWidth: "100%" }}>
                <Grid container xs={12}>
                  <StyledHead>
                    <StyledUrlBox>Path</StyledUrlBox>
                    <StyledNameBox>Title</StyledNameBox>
                    <StyledDataBox>Date</StyledDataBox>
                    <StyledActionBox>
                      <ActionWrapper>Action</ActionWrapper>
                    </StyledActionBox>
                  </StyledHead>
                  <StyledTable>
                    <StyledTBody>{renderContent(posts)}</StyledTBody>
                  </StyledTable>
                </Grid>
              </Box>
            </BoxWrapper>
          ) : (
            <NoTopicsWrapper>
              <ImageBackground></ImageBackground>
              <NoTopicsTitleWrapper>
                <StyledTitleNoTopics>Have no content for project</StyledTitleNoTopics>
                <LinkWrapper to={`${AdminRoutes.Contents}/add?projectId=${projectIdFilter}`}>
                  <AdminMainButton onClick={handleResetPath} text={BTN_TITLE.CREATE} />
                </LinkWrapper>
              </NoTopicsTitleWrapper>
            </NoTopicsWrapper>
          )}
        </>
      ) : (
        <NoTopicsWrapper>
          <ImageBackground></ImageBackground>
          <NoTopicsTitleWrapper>
            <StyledTitleNoTopics>No project added yet</StyledTitleNoTopics>
            <LinkWrapper to={`${AdminRoutes.Project}/add`}>
              <AdminMainButton text={BTN_TITLE.CREATE} />
            </LinkWrapper>
          </NoTopicsTitleWrapper>
        </NoTopicsWrapper>
      )}
    </Container>
  );
};

export default Contents;
