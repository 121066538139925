import * as React from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { Grid } from "@mui/material";
import "react-markdown-editor-lite/lib/index.css";
import { useAppSelector } from "../../hooks/useAppSelector";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { initialValues, initialValuesI, initialValuesEdit } from "./config";
import { BTN_TITLE, INPUT_LABEL, LoadingResultsT, PROJECT_ACTIONS } from "../../helpers/constants";
import { createProjectThunk, editProjectThunk, getProjectByIdThunk } from "../../redux/projects/thunks";
import AdminMainButton from "../../components/AdminMainButton";
import CancelButton from "../../components/CancelButton";
import Container from "../../components/Container";
import Notification from "../../utils/Notification";
import { AdminRoutes } from "../../config/routes";
import {
  StyledGrid,
  ButtonsWrapper,
  SaveButtonWrapper,
  StyledTitle,
  TextFieldWrapper,
  StyledGridBottom,
  StyledGridItem,
  StyledTitleWrapper,
  FormWrapper,
  StyledTitleGrid,
} from "./styles";
import { loadingSelector, projectStatusSelector, selectedProjectSelector } from "../../redux/projects/selectors";
import projectSlice from "../../redux/projects/slice";
import { validateProjectForm } from "./validation";

const Project: React.FC = () => {
  const loading = useSelector(loadingSelector);
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const project = useAppSelector(selectedProjectSelector);
  const { action, id } = params;
  const isEdit = action === PROJECT_ACTIONS.URL_EDIT;
  const actionTitle = isEdit ? PROJECT_ACTIONS.EDIT : PROJECT_ACTIONS.ADD;
  const [notificationIsOpen, setNotification] = useState(false);
  const projectStatuses = useAppSelector(projectStatusSelector);
  const succeedCreate = projectStatuses["projects/createProjectThunk"]?.success;

  const formik = useFormik({
    initialValues: isEdit ? initialValuesEdit(project) : initialValues(),
    enableReinitialize: true,
    validate: validateProjectForm,
    onSubmit: (values, helpers) => {
      handleSubmit(values);
      !isEdit && succeedCreate && helpers.resetForm();
    },
  });

  React.useEffect(() => {
    isEdit && dispatch(getProjectByIdThunk(id || ""));
  }, [dispatch, isEdit, id]);

  React.useEffect(() => {
    if (loading === LoadingResultsT.SUCCEEDED) {
      dispatch(projectSlice.actions.resetSelectedProject());
      navigate("/projects");
    }
  }, [navigate, dispatch, loading]);

  const hadleCloseNotification = () => {
    setNotification(false);
    dispatch(projectSlice.actions.resetStatus());
  };

  const handleOpenNotification = () => setNotification(true);

  const handleSubmit = (values: initialValuesI) => {
    handleOpenNotification();
    if (isEdit) {
      if (id) {
        dispatch(editProjectThunk({ id, ...values }));
      }
    } else {
      dispatch(createProjectThunk(values));
    }
  };

  const cancelHandler = () => {
    navigate(AdminRoutes.Projects);
    dispatch(projectSlice.actions.resetSelectedProject());
  };

  return (
    <Container>
      <StyledTitleWrapper>
        <StyledTitle>{actionTitle}</StyledTitle>
      </StyledTitleWrapper>

      {actionTitle !== "edit project" || isEdit ? (
        <FormWrapper>
          <form
            noValidate
            onSubmit={formik.handleSubmit}
            onKeyDown={(e: React.KeyboardEvent<HTMLElement>) => {
              const element = e.target as HTMLElement;
              if (element.nodeName !== "TEXTAREA" && e.key === "Enter") {
                e.preventDefault();
              }
            }}
          >
            <StyledGrid justifyContent="space-between" container xs={9}>
              <StyledTitleGrid item xs={8}>
                <TextFieldWrapper
                  size="small"
                  fullWidth
                  onChange={formik.handleChange}
                  label={INPUT_LABEL.NAME}
                  name="name"
                  value={formik.values.name}
                  helperText={formik.touched["name"] && formik.errors["name"]}
                />
              </StyledTitleGrid>
            </StyledGrid>
          </form>

          <form
            noValidate
            onSubmit={formik.handleSubmit}
            onKeyDown={(e: React.KeyboardEvent<HTMLElement>) => {
              const element = e.target as HTMLElement;

              if (element.nodeName !== "TEXTAREA" && e.key === "Enter") {
                e.preventDefault();
              }
            }}
          >
            <StyledGrid container xs={12}>
              <StyledGridBottom container xs={12}>
                <Grid container xs={6}>
                  <StyledGridItem item xs={12}>
                    <TextFieldWrapper
                      size="small"
                      fullWidth
                      onChange={formik.handleChange}
                      label={INPUT_LABEL.PATH}
                      name="path"
                      value={formik.values.path}
                      helperText={formik.touched["path"] && formik.errors["path"]}
                    />
                  </StyledGridItem>
                </Grid>

                <Grid item>
                  <ButtonsWrapper>
                    <Grid item xs={5}>
                      <CancelButton onClick={cancelHandler} text={BTN_TITLE.CANCEL} type="button" />
                    </Grid>
                    <SaveButtonWrapper item xs={5}>
                      <AdminMainButton text={BTN_TITLE.SAVE} />
                    </SaveButtonWrapper>
                  </ButtonsWrapper>
                </Grid>
              </StyledGridBottom>
            </StyledGrid>
          </form>
          {notificationIsOpen && <Notification isOpen={notificationIsOpen} onClose={hadleCloseNotification} />}
        </FormWrapper>
      ) : (
        <h1>loading</h1>
      )}
    </Container>
  );
};

export default Project;
