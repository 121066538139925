import { createSelector } from "reselect";
import { RootState } from "..";

const projectsState = (state: RootState) => state.project;

export const projectsSelector = createSelector(projectsState, ({ projects }) => projects);

export const loadingSelector = createSelector(projectsState, ({ loading }) => loading);

export const projectStatusSelector = createSelector(projectsState, ({ statuses }) => statuses);

export const selectedProjectSelector = createSelector(projectsState, ({ selectedProject }) => selectedProject);
