import { Project as ProjectType } from "../../types/post";

export interface initialValuesI {
  name: string;
  path: string;
}

export interface initialValuesEditI {
  id: string;
  name: string;
  path: string;
}

export const initialValues = (): initialValuesI => ({
  name: "",
  path: "",
});

export const initialValuesEdit = (project: ProjectType | null): initialValuesEditI => ({
  id: project?._id ?? "",
  name: project?.name ?? "",
  path: project?.path ?? "",
});
