import * as React from "react";
import Container from "../../components/Container";
import LongMenu from "../../components/LongMenu/LongMenu";
import { useEffect } from "react";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import { usersSelector } from "../../redux/users/selectors";
import { getAllUsersThunk } from "../../redux/users/thunks";
import { User } from "../../types/users";
import ResetPasswordUserModal from "../../components/ResetPasswordUserModal/ResetPasswordUserModal";
import {
  MainWrapper,
  NoTopicsWrapper,
  ImageBackground,
  FotoUser,
  UserName,
  AllUsersWrapper,
  CreateUsersWrapper,
  TitleUsers,
  UsersList,
  UserListWrapper,
  StatusUser,
  InfoUser,
  UserRole,
  NoTopicsTitleWrapper,
  StyledTitleNoTopics,
} from "./styles";
import FormNewMembers from "../../components/FormNewMembers";
import usersSlice from "../../redux/users/slice";
import { userSelector } from "../../redux/auth/selectors";

const Members: React.FC = () => {
  const dispatch = useAppDispatch();
  const users = useAppSelector(usersSelector);
  const authUser: any = useAppSelector(userSelector);
  const isAdmin: boolean = Boolean(authUser?.user?.role);
  const allUsersDiv = React.useRef<HTMLDivElement>(null);
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [selectedUserId, setSelectedUserId] = React.useState<string>("");
  const [formEmpty, setFormEmpty] = React.useState<boolean>(true);

  const handleFormMemberModalOpen = () => {
    if (!formEmpty) {
      dispatch(usersSlice.actions.modalUsers(true));
    }
  };

  useEffect(() => {
    dispatch(getAllUsersThunk({ limit: 100, page: 1 }));
  }, []);

  const renderUsers = (arr: any) => {
    return arr?.map((user: User) => (
      <UserListWrapper
        onClick={() => handleFormMemberModalOpen()}
        key={user._id}
      >
        <InfoUser>
          <FotoUser>
            {user.firstName
              ? `${user.firstName?.[0]}${user.lastName?.[0]}`
              : user.email.slice(0, 2).toUpperCase()}
          </FotoUser>
          <UserName>
            {user.firstName
              ? `${user.firstName} ${user.lastName}`
              : user.email.split(`@`)[0]}
          </UserName>
        </InfoUser>
        <StatusUser>
          <UserRole>{user.role}</UserRole>
          <LongMenu
            userId={user._id}
            setSelectedUserId={setSelectedUserId}
            setOpenModal={setOpenModal}
            isAdmin={isAdmin}
          />
        </StatusUser>
      </UserListWrapper>
    ));
  };

  return (
    <Container>
      <MainWrapper>
        {users?.docs.length ? (
          <AllUsersWrapper ref={allUsersDiv}>
            <TitleUsers>All members</TitleUsers>
            <UsersList>{renderUsers(users?.docs)}</UsersList>
          </AllUsersWrapper>
        ) : (
          <NoTopicsWrapper>
            <ImageBackground></ImageBackground>
            <NoTopicsTitleWrapper>
              <StyledTitleNoTopics>Have no members yet</StyledTitleNoTopics>
            </NoTopicsTitleWrapper>
          </NoTopicsWrapper>
        )}
        <CreateUsersWrapper>
          <FormNewMembers setFormEmpty={setFormEmpty}></FormNewMembers>
        </CreateUsersWrapper>
      </MainWrapper>
      <ResetPasswordUserModal
        selectedUserId={selectedUserId}
        setOpenModal={setOpenModal}
        openModal={openModal}
      />
    </Container>
  );
};

export default Members;
