import styled from "@emotion/styled";

export const MainWrapper = styled("div")(() => ({
  width: "100%",
  height: "100%",
  position: "absolute",
  background: "rgba(0, 0, 0, 0.15)",
  zIndex: 3,
}));
export const ModalWrapper = styled("div")(() => ({
  width: 505,
  height: 260,
  borderRadius: 12,
  background: "#FFFFFF",
  margin: "15% auto",
}));
export const ContentWrapper = styled("div")(() => ({
  padding: "48px 65px",
}));
export const Title = styled("p")(() => ({
  fontFamily: "Montserrat, sans-serif",
  fontWeight: 600,
  fontSize: 20,
  lineHeight: "130%",
}));
export const ButtonsWrapper = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",
  marginTop: 30,
}));

export const ModalButton = styled("div")(({ theme, className }) => ({
  width: 150,
  height: 50,
  background: className?.includes("blue")
    ? "linear-gradient(93.62deg, #69BDFF 3.14%, #2196F3 87.37%);"
    : "#FEB25B",
  borderRadius: 12,
  fontSize: 12,
  fontWeight: 700,
  color: "#FFFFFF",
  alignItems: "center",
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
}));
