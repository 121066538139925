import React, { useEffect, useState } from "react";
import InputFormNewMembers from "../InputFormNewMembers";
import { FormNewMembers as Props } from "../../types/members";
import {
  TitleForm,
  FormWrapper,
  FotoSection,
  NoFotoUser,
  ButtonAddImage,
  InputFormSection,
  RoleSection,
  RoleButton,
  RoleButtonText,
  SaveButton,
  SaveButtonWrapper,
  SaveButtonText,
  MainWrapper,
  InputWrapper,
} from "./style";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import { formCleanerSelector } from "../../redux/users/selectors";
import usersSlice from "../../redux/users/slice";
import { createUserThunk, getAllUsersThunk } from "../../redux/users/thunks";
import {
  validateConfirmPassword,
  validateEmail,
  validateName,
  validatePassword,
} from "./ErrorValitation";

const FormNewMembers: React.FC<Props> = ({ setFormEmpty }) => {
  const [firstName, setFirstName] = useState<string>("");
  const [errorFirstName, setErrorFirstName] = useState<boolean>(false);
  const [lastName, setLastName] = useState<string>("");
  const [errorLastName, setErrorLastName] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [errorEmail, setErrorEmail] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [errorPassword, setErrorPassword] = useState<boolean>(false);
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [errorConfirmPassword, setErrorConfirmPassword] =
    useState<boolean>(false);
  const [isDisableSave, setIsDisableSave] = useState<boolean>(true);
  const [role, setRole] = useState<any>("Admin");
  const [status, setStatus] = useState<any>("Activated");
  const clearForm = useAppSelector(formCleanerSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    errorFirstName ||
    firstName === "" ||
    lastName === "" ||
    email === "" ||
    password === "" ||
    confirmPassword === "" ||
    errorLastName ||
    errorEmail ||
    errorPassword ||
    errorConfirmPassword
      ? setIsDisableSave(true)
      : setIsDisableSave(false);
  }, [
    firstName,
    lastName,
    email,
    password,
    confirmPassword,
    errorFirstName,
    errorLastName,
    errorEmail,
    errorPassword,
    errorConfirmPassword,
  ]);
  useEffect(() => {
    if (clearForm) {
      setFirstName("");
      setLastName("");
      setEmail("");
      setPassword("");
      setConfirmPassword("");
      setRole("Admin");
      setStatus("Activated");
      dispatch(usersSlice.actions.formCleaner(false));
    }
  }, [clearForm]);

  useEffect(() => {
    !validateName(firstName)
      ? setErrorFirstName(true)
      : setErrorFirstName(false);
    !validateName(lastName) ? setErrorLastName(true) : setErrorLastName(false);
    !validateEmail(email) ? setErrorEmail(true) : setErrorEmail(false);
    !validatePassword(password)
      ? setErrorPassword(true)
      : setErrorPassword(false);
    !validateConfirmPassword(password, confirmPassword)
      ? setErrorConfirmPassword(true)
      : setErrorConfirmPassword(false);
  }, [firstName, lastName, email, password, confirmPassword]);

  useEffect(() => {
    if (
      firstName !== "" ||
      lastName !== "" ||
      email !== "" ||
      password !== "" ||
      confirmPassword !== ""
    ) {
      setFormEmpty(false);
    } else {
      setFormEmpty(true);
    }
  }, [firstName, lastName, email, password, confirmPassword]);

  const createUser = async () => {
    const data = {
      image: "fs",
      firstName: firstName,
      lastName: lastName,
      email: email,
      password: password,
      activeStatus: status,
    };
    await dispatch(createUserThunk(data));
    dispatch(getAllUsersThunk({ limit: 100, page: 1 }));
    dispatch(usersSlice.actions.formCleaner(true));
    dispatch(usersSlice.actions.formCleaner(false));
  };

  return (
    <MainWrapper>
      <TitleForm>Add new member</TitleForm>
      <FormWrapper>
        <FotoSection>
          <NoFotoUser />
          <ButtonAddImage>ADD IMAGE</ButtonAddImage>
        </FotoSection>
        <InputFormSection>
          <InputWrapper>
            <InputFormNewMembers
              type={"text"}
              value={firstName}
              setFunction={setFirstName}
              placeholder={"First Name"}
              error={errorFirstName}
              name={"firstName"}
            />
          </InputWrapper>
          <InputWrapper>
            <InputFormNewMembers
              type={"text"}
              value={lastName}
              error={errorLastName}
              setFunction={setLastName}
              placeholder={"Last Name"}
              name={"lastName"}
            />
          </InputWrapper>
          <InputWrapper>
            <InputFormNewMembers
              type={"text"}
              value={email}
              setFunction={setEmail}
              error={errorEmail}
              placeholder={"Email"}
              name={"email"}
            />
          </InputWrapper>

          <InputWrapper>
            <InputFormNewMembers
              type={"password"}
              value={password}
              setFunction={setPassword}
              error={errorPassword}
              name={"password"}
              placeholder={"Password"}
            />
          </InputWrapper>

          <InputWrapper>
            <InputFormNewMembers
              type={"password"}
              name={"confirmPassword"}
              value={confirmPassword}
              error={errorConfirmPassword}
              setFunction={setConfirmPassword}
              placeholder={"Confirm Password"}
            />
          </InputWrapper>
        </InputFormSection>
        <RoleSection>
          <RoleButton
            onClick={() => setRole("Admin")}
            className={role === "Admin" ? "active" : ""}
          >
            <RoleButtonText>Admin</RoleButtonText>
          </RoleButton>
          <RoleButton
            onClick={() => setRole("Moderator")}
            className={role === "Moderator" ? "active" : ""}
          >
            <RoleButtonText>Moderator</RoleButtonText>
          </RoleButton>
          <RoleButton
            onClick={() => setStatus("Activated")}
            className={status === "Activated" ? "active" : ""}
          >
            <RoleButtonText>Activated</RoleButtonText>
          </RoleButton>
          <RoleButton
            onClick={() => setStatus("Deactivated")}
            className={status === "Deactivated" ? "active" : ""}
          >
            <RoleButtonText>Deactivated</RoleButtonText>
          </RoleButton>
        </RoleSection>
        <SaveButtonWrapper>
          <div></div>
          <SaveButton disabled={isDisableSave} onClick={() => createUser()}>
            <SaveButtonText>save</SaveButtonText>
          </SaveButton>
        </SaveButtonWrapper>
      </FormWrapper>
    </MainWrapper>
  );
};

export default FormNewMembers;
