import styled from "@emotion/styled";

export const InputWrapper = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  position: "relative",
}));

export const InputForm = styled("input")(() => ({
  border: "1px solid rgba(113, 115, 132, 0.5)",
  height: 25,
  borderRadius: 10,
  padding: "10px 15px",
  "&:invalid": {
    border: "1px solid red",
  },
  "&:invalid:focus": {
    outline: "none",
    border: "1px solid red",
  },
}));
export const ClosedEyeButton = styled("button")(() => ({
  backgroundColor: "transparent",
  backgroundImage: "url('/images/closed-eye.svg')",
  width: 29,
  height: 29,
  border: "none",
  outline: "none",
  cursor: "pointer",
  position: "absolute",
  right: 15,
  top: -15,
  marginTop: 26,
  marginLeft: -50,
}));

export const OpenEyeButton = styled("button")(() => ({
  backgroundColor: "transparent",
  backgroundImage: "url('/images/open-eye.svg')",
  width: 29,
  height: 29,
  border: "none",
  outline: "none",
  cursor: "pointer",
  position: "absolute",
  right: 15,
  top: -15,
  marginTop: 26,
  marginLeft: -50,
}));
export const ErrorText = styled("p")(() => ({
  color: "red",
  margin: 0,
}));
