import { ValidateContentMessages } from "../../helpers/constants";
import { initialValuesI } from "./config";

export interface ContentValidationErrors {
  path?: string;
  content?: string;
  metaTitle?: string;
  metaDescription?: string;
  metaKeywords?: string;
}

export const validateContentForm = (values: initialValuesI): ContentValidationErrors => {
  const errors: ContentValidationErrors = {};

  if (!values.path) {
    errors.path = ValidateContentMessages.errorContentPathRequired;
  } else if (values.path.length < 3) {
    errors.path = ValidateContentMessages.errorContentPathLength;
  }
  if (!values.content) {
    errors.content = ValidateContentMessages.errorContentRequired;
  }
  if (!values.metaTitle) {
    errors.metaTitle = ValidateContentMessages.errorContentMetaTitleRequired;
  }
  if (!values.metaDescription) {
    errors.metaDescription = ValidateContentMessages.errorContentMetaDescriptionRequired;
  }
  if (!values.metaKeywords) {
    errors.metaKeywords = ValidateContentMessages.errorContentMetaKeywordsRequired;
  }

  return errors;
};

export const validateContentTranslationForm = (values: initialValuesI): ContentValidationErrors => {
  const errors: ContentValidationErrors = {};

  if (!values.content) {
    errors.content = ValidateContentMessages.errorContentRequired;
  }
  if (!values.metaTitle) {
    errors.metaTitle = ValidateContentMessages.errorContentMetaTitleRequired;
  }
  if (!values.metaDescription) {
    errors.metaDescription = ValidateContentMessages.errorContentMetaDescriptionRequired;
  }
  if (!values.metaKeywords) {
    errors.metaKeywords = ValidateContentMessages.errorContentMetaKeywordsRequired;
  }

  return errors;
};
