import * as React from "react";
import { useEffect } from "react";
import { Box, Grid, Tooltip } from "@mui/material";

import { useAppSelector } from "../../hooks/useAppSelector";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { getMyProjectsThunk } from "../../redux/projects/thunks";
import { AdminRoutes } from "../../config/routes";
import { Project } from "../../types/post";
import { BTN_TITLE, ICON_TITLE } from "../../helpers/constants";
import AdminMainButton from "../../components/AdminMainButton";
import {
  LinkWrapper,
  StyledStatusButton,
  StyledTable,
  StyledTBody,
  StyledTData,
  StyledTRow,
  StyledTitleNoTopics,
  NoTopicsWrapper,
  ImageBackground,
  NoTopicsTitleWrapper,
  StyledTDataUrl,
  StyledTDataName,
  StyledHead,
  StyledNameBox,
  StyledStatusBox,
  StyledUrlBox,
  StyledDataBox,
  StyledActionBox,
  StyledTDataStatus,
  StyledTDataDate,
  StyleName,
  StyledUrl,
  StyledLine,
  ActionWrapper,
  GridWrapper,
  BoxWrapper,
  StyledGridSelect,
  TopicHeader,
} from "./styles";
import ActionTopicButton from "../../components/ActionTopicButton";
import { format } from "date-fns";
import Container from "../../components/Container";
import { projectsSelector } from "../../redux/projects/selectors";

const Projects: React.FC = () => {
  const dispatch = useAppDispatch();
  const projects = useAppSelector(projectsSelector);

  useEffect(() => {
    dispatch(getMyProjectsThunk());
  }, [dispatch]);

  const renderProjects = (arr: any) => {
    return arr?.map((projects: Project) => (
      <>
        <StyledTRow key={projects._id}>
          <StyledTDataName>
            <StyleName>{projects.name}</StyleName>
          </StyledTDataName>
          <StyledTDataStatus>
            <StyledStatusButton
              style={
                projects.status === "ACTIVE"
                  ? { background: "#EBF6FF", color: "#2196F3" }
                  : projects.status === "ARCHIVED"
                  ? { background: "#2196F3" }
                  : { background: "#CAC7C7" }
              }
            >
              {projects.status}
            </StyledStatusButton>
          </StyledTDataStatus>
          <StyledTDataUrl>
            <StyledUrl>{projects.path}</StyledUrl>
          </StyledTDataUrl>
          <StyledTDataDate>{format(new Date(`${projects.createdAt}`), "dd.MM.yyyy")}</StyledTDataDate>
          <StyledTData>
            <LinkWrapper to={`${AdminRoutes.Project}/edit/${projects._id}`}>
              <Tooltip title={ICON_TITLE.EDIT_POST} arrow>
                <ActionTopicButton text={BTN_TITLE.EDIT} />
              </Tooltip>
            </LinkWrapper>
          </StyledTData>
        </StyledTRow>
        <StyledLine></StyledLine>
      </>
    ));
  };

  return (
    <Container>
      {projects ? (
        <BoxWrapper>
          <StyledGridSelect item xs={2}>
            <TopicHeader>
              <GridWrapper container justifyContent="space-between">
                <Grid item></Grid>
                <Grid item>
                  <LinkWrapper to={`${AdminRoutes.Project}/add`}>
                    <AdminMainButton text={BTN_TITLE.CREATE} />
                  </LinkWrapper>
                </Grid>
              </GridWrapper>
            </TopicHeader>
          </StyledGridSelect>
          <Box sx={{ maxWidth: "100%" }}>
            <Grid container xs={12}>
              <StyledHead>
                <StyledNameBox>Name</StyledNameBox>
                <StyledStatusBox>Status</StyledStatusBox>
                <StyledUrlBox>Path</StyledUrlBox>
                <StyledDataBox>Date</StyledDataBox>
                <StyledActionBox>
                  <ActionWrapper>Action</ActionWrapper>
                </StyledActionBox>
              </StyledHead>
              <StyledTable>
                <StyledTBody>{renderProjects(projects)}</StyledTBody>
              </StyledTable>
            </Grid>
          </Box>
        </BoxWrapper>
      ) : (
        <NoTopicsWrapper>
          <ImageBackground></ImageBackground>
          <NoTopicsTitleWrapper>
            <StyledTitleNoTopics>Have no projects yet</StyledTitleNoTopics>
            <LinkWrapper to={`${AdminRoutes.Project}/add`}>
              <AdminMainButton text={BTN_TITLE.CREATE} />
            </LinkWrapper>
          </NoTopicsTitleWrapper>
        </NoTopicsWrapper>
      )}
    </Container>
  );
};

export default Projects;
