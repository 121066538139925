import { createSelector } from "reselect";
import { RootState } from "..";

const contentsState = (state: RootState) => state.contents;

export const contentSelector = createSelector(contentsState, ({ contents }) => contents);

export const selectedContentSelector = createSelector(contentsState, ({ selectedContent }) => selectedContent);

export const loadingSelector = createSelector(contentsState, ({ loading }) => loading);

export const contentStatusSelector = createSelector(contentsState, ({ statuses }) => statuses);

export const selectedContentPathSelector = createSelector(contentsState, ({ path }) => path);
