/**
 * @description Enum containing routes to all pages rendered by react-router
 */
export enum AdminRoutes {
  Login = "/",
  Projects = "/projects",
  Project = "/project",
  Contents = "/contents",
  Members = "/members",
  SignUp = "/onboarding",
  ResetPassword = "/reset-password/:token",
  Topics = "/topics",
  Topic = "/topic",
}
