export const ErrorsValidation = (error: string) => {
  switch (error) {
    case "firstName":
      return "First Name must contain from 2 to 20 letters";
    case "lastName":
      return "Last Name must contain from 2 to 20 letters";
    case "email":
      return "Enter correct email";
    case "password":
      return "The password must contain small and large latin letters, numbers and special characters and minimum 8 characters";
    case "confirmPassword":
      return "Passwords do not match";
    default:
      return "Error";
  }
};

export function validateEmail(email: string) {
  if (email === "") {
    return true;
  }
  if (
    /([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$/.test(
      email
    )
  ) {
    return true;
  }
  return false;
}

export function validateName(name: string) {
  if (name === "") {
    return true;
  }
  if (/[a-zA-Z0-9]{2,20}$/.test(name)) {
    return true;
  }
  return false;
}
export function validatePassword(password: string) {
  if (password === "") {
    return true;
  }
  if (
    /(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{8,}/.test(
      password
    )
  ) {
    return true;
  }
  return false;
}

export function validateConfirmPassword(
  password: string,
  confirmPassword: string
) {
  if (confirmPassword === "") {
    return true;
  }
  if (password === confirmPassword) {
    return true;
  }
  return false;
}
