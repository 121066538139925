import {
  StyledContainer,
  StyledAppVersion,
  LinkWrapper,
  StyledLink,
  StyledButton,
  StyledImg,
  StyledMenuImg,
  StyledMenImg,
  ButtonWrapper,
  ButtonImgWrapper,
  ButtonTextWrapper,
  LogoWrapper,
} from "./styles";

import { AdminRoutes } from "../../config/routes";
import { useLocation } from "react-router-dom";
import { TOPICS_BUTTON } from "../../helpers/constants";

export default function SideBar() {
  const location = useLocation();
  const isProjects = location.pathname === TOPICS_BUTTON.PROJECTS;
  const isTopics = location.pathname === TOPICS_BUTTON.TOPICS;
  const isArticles = location.pathname === TOPICS_BUTTON.CONTENTS;
  const isMembers = location.pathname === TOPICS_BUTTON.MEMBERS;

  const isTabSelected = (isSelected: boolean) => {
    return isSelected ? { background: "#689DF6" } : { background: "inherit" };
  };

  return (
    <StyledContainer>
      <LinkWrapper>
        <LogoWrapper>
          <StyledImg src="/images/logo.svg" alt="logo image" />
        </LogoWrapper>

        <ButtonWrapper>
          <StyledLink to={AdminRoutes.Topics}>
            <StyledButton style={isTabSelected(isTopics)}>
              <ButtonImgWrapper>
                <StyledMenuImg src="/images/feed.svg" alt="menu" />
              </ButtonImgWrapper>
              <ButtonTextWrapper>Topics</ButtonTextWrapper>
            </StyledButton>
          </StyledLink>
          <StyledLink to={AdminRoutes.Projects}>
            <StyledButton style={isTabSelected(isProjects)}>
              <ButtonImgWrapper>
                <StyledMenImg src="/images/projects.svg" alt="Projects" />
              </ButtonImgWrapper>
              <ButtonTextWrapper>Projects</ButtonTextWrapper>
            </StyledButton>
          </StyledLink>
          <StyledLink to={AdminRoutes.Contents}>
            <StyledButton style={isTabSelected(isArticles)}>
              <ButtonImgWrapper>
                <StyledMenImg src="/images/content.svg" alt="Contents" />
              </ButtonImgWrapper>
              <ButtonTextWrapper>Contents</ButtonTextWrapper>
            </StyledButton>
          </StyledLink>
          <StyledLink to={AdminRoutes.Members}>
            <StyledButton style={isTabSelected(isMembers)}>
              <ButtonImgWrapper>
                <StyledMenImg src="/images/men.svg" alt="Members" />
              </ButtonImgWrapper>
              <ButtonTextWrapper>Members</ButtonTextWrapper>
            </StyledButton>
          </StyledLink>
        </ButtonWrapper>
      </LinkWrapper>

      <StyledAppVersion>v0.2</StyledAppVersion>
    </StyledContainer>
  );
}
