import styled from "@emotion/styled";

export const StyledTitleNoTopics = styled("h2")(() => ({
  fontWeight: 700,
  fontSize: 48,
  color: "black",
  marginBottom: "4vw",
}));
export const NoTopicsWrapper = styled("div")(() => ({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-around",
  alignItems: "center",
  marginTop: "15vh",
}));
export const ImageBackground = styled("div")(() => ({
  width: 470,
  height: 457,
  backgroundImage: "url('/images/no-topic.webp')",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
}));
export const NoTopicsTitleWrapper = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  alignItems: "center",
}));
export const MainWrapper = styled("div")(() => ({
  margin: "40px 10px 0 10px",
  height: "93%",
  display: "grid",
  gridTemplateColumns: "65% 1fr",
  gridColumnGap: 40,
}));

export const AllUsersWrapper = styled("div")(() => ({
  width: "100%",
  height: "100%",
  background: "white",
  overflow: "auto",
}));

export const TitleUsers = styled("p")(() => ({
  fontFamily: "Nunito, sans-serif",
  color: "#2196F3",
  textTransform: "uppercase",
  fontWeight: 700,
  margin: "0 0 30px 0",
}));

export const UsersList = styled("div")(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
}));
export const UserListWrapper = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",
  margin: "12px 0",
  height: 55,
  background: "#F9F9F9",
  borderRadius: 20,
  marginTop: 10,
  color: "black",
}));

export const InfoUser = styled("div")(() => ({
  width: "fit-content",
  height: "100%",
  display: "flex",
  alignItems: "center",
}));
export const StatusUser = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  width: "fit-content",
  height: "100%",
  svg: {
    color: "#2196F3",
    width: "1.5em",
    height: "1.5em",
  },
}));
export const FotoUser = styled("div")(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: 34,
  height: 34,
  background: "#C4C4C4",
  backgroundSize: "contain",
  borderRadius: "50%",
  marginLeft: 20,
}));
export const UserName = styled("p")(() => ({
  fontFamily: "Montserrat, sans-serif",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: 16,
  lineHeight: "130%",
  marginLeft: 30,
}));
export const UserRole = styled("p")(() => ({
  fontFamily: "Montserrat, sans-serif",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: 10,
  lineHeight: "130%",
  color: "#A4A4A4",
  marginRight: 80,
  width: 80,
  textAlign: "center",
}));
export const CreateUsersWrapper = styled("div")(() => ({
  position: "relative",
  width: "100%",
}));
export const TextDeveloping = styled("p")(() => ({
  position: "absolute",
  top: "50%",
  fontSize: 58,
  left: "50%",
  width: "fit-content",
  color: "black",
  transform: "translate(-50%, -50%)",
}));
