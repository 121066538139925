import * as React from "react";
import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import MdEditor from "react-markdown-editor-lite";
import MarkdownIt from "markdown-it";
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import "react-markdown-editor-lite/lib/index.css";
import { useAppSelector } from "../../hooks/useAppSelector";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { initialValues, initialValuesI, initialValuesEdit } from "./config";
import {
  BTN_TITLE,
  INPUT_LABEL,
  LoadingResultsT,
  CONTENT_ACTIONS,
  LANGUAGES_TYPES_ENUM,
} from "../../helpers/constants";
import AdminMainButton from "../../components/AdminMainButton";
import CancelButton from "../../components/CancelButton";
import Container from "../../components/Container";
import Notification from "../../utils/Notification";
import { AdminRoutes } from "../../config/routes";
import {
  StyledGrid,
  ButtonsWrapper,
  SaveButtonWrapper,
  StyledTitle,
  TextFieldWrapper,
  StyledGridBottom,
  StyledGridItem,
  StyledTitleWrapper,
  FormWrapper,
  StyledFormHelperText,
  FlagImg,
} from "./styles";
import {
  contentStatusSelector,
  loadingSelector,
  selectedContentPathSelector,
  selectedContentSelector,
} from "../../redux/contents/selectors";
import {
  createContentThunk,
  createContentTranslationThunk,
  editContentThunk,
  editContentTranslationThunk,
  getContentByIdThunk,
  getContentTranslationByIdThunk,
} from "../../redux/contents/thunks";
import contentSlice from "../../redux/contents/slice";
import { validateContentForm, validateContentTranslationForm } from "./validation";

const Content: React.FC = () => {
  const loading = useSelector(loadingSelector);
  const params = useParams();
  const location = useLocation();
  const param = new URLSearchParams(location.search);
  const projectId = param.get("projectId");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const content = useAppSelector(selectedContentSelector);
  const mdParser = new MarkdownIt();
  const { action, id } = params;
  const isEdit = action === CONTENT_ACTIONS.URL_EDIT;
  const actionTitle = isEdit ? CONTENT_ACTIONS.EDIT : CONTENT_ACTIONS.ADD;
  const [notificationIsOpen, setNotification] = useState(false);
  const contentStatuses = useAppSelector(contentStatusSelector);
  const succeedCreate = contentStatuses["contents/createContentThunk"]?.success;
  const [selectedLanguage, setSelectedLanguage] = useState(LANGUAGES_TYPES_ENUM.ENGLISH);
  const contentPath = useAppSelector(selectedContentPathSelector);
  const [hasChanges, setHasChanges] = useState(false);

  const formik = useFormik({
    initialValues: isEdit ? initialValuesEdit(content) : initialValues(),
    enableReinitialize: true,
    validate: selectedLanguage === LANGUAGES_TYPES_ENUM.ENGLISH ? validateContentForm : validateContentTranslationForm,
    onSubmit: (values, helpers) => {
      handleSubmit(values);
      !isEdit && !succeedCreate && succeedCreate && helpers.resetForm();
    },
  });

  React.useEffect(() => {
    isEdit && dispatch(getContentByIdThunk(id || ""));
  }, [dispatch, isEdit, id]);

  React.useEffect(() => {
    if (loading === LoadingResultsT.SUCCEEDED) {
      dispatch(contentSlice.actions.resetSelectedContent());
      navigate("/contents");
    }
  }, [loading, dispatch, navigate]);

  React.useEffect(() => {
    if (selectedLanguage && id) {
      if (selectedLanguage === LANGUAGES_TYPES_ENUM.ENGLISH) {
        dispatch(getContentByIdThunk(id));
      } else {
        dispatch(getContentTranslationByIdThunk({ contentId: id, languageCode: selectedLanguage }));
      }
    }
  }, [selectedLanguage, id, dispatch]);

  React.useEffect(() => {
    if (isEdit) {
      const hasChanges =
        JSON.stringify(formik.values) !== JSON.stringify(isEdit ? initialValuesEdit(content) : initialValues());

      setHasChanges(hasChanges);
    }
  }, [formik.values, content, isEdit]);

  const handleChangeMarkdown = (text: string) => formik.setFieldValue("content", text);

  const hadleCloseNotification = () => {
    setNotification(false);
    dispatch(contentSlice.actions.resetStatus());
  };

  const handleOpenNotification = () => setNotification(true);

  const handleSubmit = (values: initialValuesI) => {
    handleOpenNotification();
    if (isEdit) {
      if (id) {
        if (selectedLanguage !== LANGUAGES_TYPES_ENUM.ENGLISH && content) {
          dispatch(editContentTranslationThunk({ contentId: id, languageCode: selectedLanguage, ...values }));
        } else if (selectedLanguage !== LANGUAGES_TYPES_ENUM.ENGLISH && !content) {
          dispatch(createContentTranslationThunk({ contentId: id, languageCode: selectedLanguage, ...values }));
        } else if (selectedLanguage === LANGUAGES_TYPES_ENUM.ENGLISH) {
          dispatch(editContentThunk({ id, ...values }));
        }
      }
    } else {
      if (projectId) {
        dispatch(createContentThunk({ id: projectId, bodyData: values }));
      }
    }
  };

  const cancelHandler = () => {
    navigate(AdminRoutes.Contents);
    dispatch(contentSlice.actions.resetSelectedContent());
  };

  const handleLanguageChange = (event: SelectChangeEvent<LANGUAGES_TYPES_ENUM>) => {
    const newLanguage = event.target.value as LANGUAGES_TYPES_ENUM;
    setSelectedLanguage(newLanguage);
  };

  return (
    <Container>
      <StyledTitleWrapper>
        <StyledTitle>{actionTitle}</StyledTitle>
      </StyledTitleWrapper>

      {actionTitle !== "edit content" || isEdit ? (
        <FormWrapper>
          <form
            noValidate
            onSubmit={formik.handleSubmit}
            onKeyDown={(e: React.KeyboardEvent<HTMLElement>) => {
              const element = e.target as HTMLElement;
              if (element.nodeName !== "TEXTAREA" && e.key === "Enter") {
                e.preventDefault();
              }
            }}
          >
            <StyledGrid justifyContent="space-between" container xs={8}>
              <StyledGridItem item xs={8}>
                <TextFieldWrapper
                  size="small"
                  fullWidth
                  onChange={formik.handleChange}
                  label={INPUT_LABEL.PATH}
                  name="path"
                  value={formik.values.path || contentPath}
                  helperText={formik.touched["path"] && formik.errors["path"]}
                  disabled={selectedLanguage !== LANGUAGES_TYPES_ENUM.ENGLISH ? true : false}
                  InputLabelProps={{ shrink: true }}
                />
              </StyledGridItem>
              {isEdit && (
                <Box>
                  <FormControl
                    size="small"
                    sx={{
                      minWidth: 120,
                    }}
                  >
                    <InputLabel id="lang-simple-select-label">Language</InputLabel>
                    <Select
                      IconComponent={KeyboardArrowDownIcon}
                      sx={{ borderRadius: "10px", padding: 0 }}
                      labelId="lang-simple-select-label"
                      id="lang-simple-select-label"
                      value={selectedLanguage}
                      label="language"
                      name="language"
                      onChange={handleLanguageChange}
                    >
                      <MenuItem value={LANGUAGES_TYPES_ENUM.ENGLISH}>
                        <FlagImg
                          width="20px"
                          height="12px"
                          src="/images/flag/flag-en.svg"
                          alt="flag of Greate Britain"
                        />
                        En
                      </MenuItem>
                      <MenuItem value={LANGUAGES_TYPES_ENUM.UKRAINIAN}>
                        <FlagImg width="20px" height="12px" src="/images/flag/flag-uk.svg" alt="flag of Ukraine" />
                        Uk
                      </MenuItem>
                      <MenuItem value={LANGUAGES_TYPES_ENUM.GERMAN}>
                        <FlagImg width="20px" height="12px" src="/images/flag/flag-de.svg" alt="flag of Germany" />
                        De
                      </MenuItem>
                      <MenuItem value={LANGUAGES_TYPES_ENUM.SPANISH}>
                        <FlagImg width="20px" height="12px" src="/images/flag/flag-es.svg" alt="flag of Spain" />
                        Es
                      </MenuItem>
                      <MenuItem value={LANGUAGES_TYPES_ENUM.POLISH}>
                        <FlagImg width="20px" height="12px" src="/images/flag/flag-pl.svg" alt="flag of Poland" />
                        Pl
                      </MenuItem>
                      <MenuItem value={LANGUAGES_TYPES_ENUM.PORTUGUESE}>
                        <FlagImg width="20px" height="12px" src="/images/flag/flag-pt.svg" alt="flag of Portugal" />
                        Pt
                      </MenuItem>
                      <MenuItem value={LANGUAGES_TYPES_ENUM.FRENCH}>
                        <FlagImg width="20px" height="12px" src="/images/flag/flag-fr.svg" alt="flag of France" />
                        Fr
                      </MenuItem>
                      <MenuItem value={LANGUAGES_TYPES_ENUM.GREEK}>
                        <FlagImg width="20px" height="12px" src="/images/flag/flag-gr.svg" alt="flag of Greese" />
                        El
                      </MenuItem>
                      <MenuItem value={LANGUAGES_TYPES_ENUM.BULGARIAN}>
                        <FlagImg width="20px" height="12px" src="/images/flag/flag-bg.svg" alt="flag of Bulgaria" />
                        Bg
                      </MenuItem>
                      <MenuItem value={LANGUAGES_TYPES_ENUM.BOSNIAN}>
                        <FlagImg width="20px" height="12px" src="/images/flag/flag-bs.svg" alt="flag of Bosnia" />
                        Bs
                      </MenuItem>
                      <MenuItem value={LANGUAGES_TYPES_ENUM.CZECH}>
                        <FlagImg
                          width="20px"
                          height="12px"
                          src="/images/flag/flag-cs.svg"
                          alt="flag of Czech Republic"
                        />
                        Cs
                      </MenuItem>
                      <MenuItem value={LANGUAGES_TYPES_ENUM.DANISH}>
                        <FlagImg width="20px" height="12px" src="/images/flag/flag-da.svg" alt="flag of Denmark" />
                        Da
                      </MenuItem>
                      <MenuItem value={LANGUAGES_TYPES_ENUM.ESTONIAN}>
                        <FlagImg width="20px" height="12px" src="/images/flag/flag-et.svg" alt="flag of Estonia" />
                        Et
                      </MenuItem>
                      <MenuItem value={LANGUAGES_TYPES_ENUM.FINNISH}>
                        <FlagImg width="20px" height="12px" src="/images/flag/flag-fi.svg" alt="flag of Finland" />
                        Fi
                      </MenuItem>
                      <MenuItem value={LANGUAGES_TYPES_ENUM.CROATIAN}>
                        <FlagImg width="20px" height="12px" src="/images/flag/flag-hr.svg" alt="flag of Croatia" />
                        Hr
                      </MenuItem>
                      <MenuItem value={LANGUAGES_TYPES_ENUM.HUNGARIAN}>
                        <FlagImg width="20px" height="12px" src="/images/flag/flag-hu.svg" alt="flag of Hungary" />
                        Hu
                      </MenuItem>
                      <MenuItem value={LANGUAGES_TYPES_ENUM.ITALIAN}>
                        <FlagImg width="20px" height="12px" src="/images/flag/flag-it.svg" alt="flag of Italy" />
                        It
                      </MenuItem>
                      <MenuItem value={LANGUAGES_TYPES_ENUM.JAPANESE}>
                        <FlagImg width="20px" height="12px" src="/images/flag/flag-ja.svg" alt="flag of Japan" />
                        Ja
                      </MenuItem>
                      <MenuItem value={LANGUAGES_TYPES_ENUM.GEORGIAN}>
                        <FlagImg width="20px" height="12px" src="/images/flag/flag-ka.svg" alt="flag of Georgia" />
                        Ka
                      </MenuItem>
                      <MenuItem value={LANGUAGES_TYPES_ENUM.KAZAKH}>
                        <FlagImg width="20px" height="12px" src="/images/flag/flag-kk.svg" alt="flag of Kazakhstan" />
                        Kk
                      </MenuItem>
                      <MenuItem value={LANGUAGES_TYPES_ENUM.KOREAN}>
                        <FlagImg width="20px" height="12px" src="/images/flag/flag-ko.svg" alt="flag of Korea" />
                        Ko
                      </MenuItem>
                      <MenuItem value={LANGUAGES_TYPES_ENUM.LITHUANIAN}>
                        <FlagImg width="20px" height="12px" src="/images/flag/flag-lt.svg" alt="flag of Lithuania" />
                        Lt
                      </MenuItem>
                      <MenuItem value={LANGUAGES_TYPES_ENUM.LATVIAN}>
                        <FlagImg width="20px" height="12px" src="/images/flag/flag-lv.svg" alt="flag of Latvia" />
                        Lv
                      </MenuItem>
                      <MenuItem value={LANGUAGES_TYPES_ENUM.MACEDONIAN}>
                        <FlagImg width="20px" height="12px" src="/images/flag/flag-mk.svg" alt="flag of Macedonia" />
                        Mk
                      </MenuItem>
                      <MenuItem value={LANGUAGES_TYPES_ENUM.MALAY}>
                        <FlagImg width="20px" height="12px" src="/images/flag/flag-ms.svg" alt="flag of Malaysia" />
                        Ms
                      </MenuItem>
                      <MenuItem value={LANGUAGES_TYPES_ENUM.DUTCH}>
                        <FlagImg width="20px" height="12px" src="/images/flag/flag-nl.svg" alt="flag of Netherlands" />
                        Nl
                      </MenuItem>
                      <MenuItem value={LANGUAGES_TYPES_ENUM.NORWEGIAN}>
                        <FlagImg width="20px" height="12px" src="/images/flag/flag-no.svg" alt="flag of Norway" />
                        No
                      </MenuItem>
                      <MenuItem value={LANGUAGES_TYPES_ENUM.ROMANIAN}>
                        <FlagImg width="20px" height="12px" src="/images/flag/flag-ro.svg" alt="flag of Romania" />
                        Ro
                      </MenuItem>
                      <MenuItem value={LANGUAGES_TYPES_ENUM.SLOVAK}>
                        <FlagImg width="20px" height="12px" src="/images/flag/flag-sk.svg" alt="flag of Slovakia" />
                        Sk
                      </MenuItem>
                      <MenuItem value={LANGUAGES_TYPES_ENUM.SLOVENIAN}>
                        <FlagImg width="20px" height="12px" src="/images/flag/flag-sl.svg" alt="flag of Slovenia" />
                        Sl
                      </MenuItem>
                      <MenuItem value={LANGUAGES_TYPES_ENUM.ALBANIAN}>
                        <FlagImg width="20px" height="12px" src="/images/flag/flag-sq.svg" alt="flag of Albania" />
                        Sq
                      </MenuItem>
                      <MenuItem value={LANGUAGES_TYPES_ENUM.SERBIAN}>
                        <FlagImg width="20px" height="12px" src="/images/flag/flag-sr.svg" alt="flag of Serbia" />
                        Sr
                      </MenuItem>
                      <MenuItem value={LANGUAGES_TYPES_ENUM.SWEDISH}>
                        <FlagImg width="20px" height="12px" src="/images/flag/flag-sv.svg" alt="flag of Sweden" />
                        Sv
                      </MenuItem>
                      <MenuItem value={LANGUAGES_TYPES_ENUM.THAI}>
                        <FlagImg width="20px" height="12px" src="/images/flag/flag-th.svg" alt="flag of Thailand" />
                        Th
                      </MenuItem>
                      <MenuItem value={LANGUAGES_TYPES_ENUM.TURKISH}>
                        <FlagImg width="20px" height="12px" src="/images/flag/flag-tr.svg" alt="flag of Turkey" />
                        Tr
                      </MenuItem>
                      <MenuItem value={LANGUAGES_TYPES_ENUM.CHINESE}>
                        <FlagImg width="20px" height="12px" src="/images/flag/flag-zh.svg" alt="flag of China" />
                        Zh
                      </MenuItem>
                      <MenuItem value={LANGUAGES_TYPES_ENUM.VIETNAMESE}>
                        <FlagImg width="20px" height="12px" src="/images/flag/flag-vi.svg" alt="flag of Vietnam" />
                        Vi
                      </MenuItem>
                      <MenuItem value={LANGUAGES_TYPES_ENUM.INDONESIAN}>
                        <FlagImg width="20px" height="12px" src="/images/flag/flag-id.svg" alt="flag of Indonesia" />
                        Id
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              )}
            </StyledGrid>
            <StyledGrid container spacing={2}>
              <Grid item xs={12}>
                <MdEditor
                  onChange={({ text }) => handleChangeMarkdown(text)}
                  value={formik.values.content}
                  style={{
                    width: "100%",
                    height: "238px",
                    borderRadius: 10,
                  }}
                  renderHTML={(text) => mdParser.render(text)}
                  plugins={[
                    "header",
                    "font-bold",
                    "font-italic",
                    "list-unordered",
                    "list-ordered",
                    "block-quote",
                    "block-wrap",
                    "block-code-inline",
                    "block-code-block",
                    "table",
                    "image",
                    "link",
                    "clear",
                    "logger",
                    "mode-toggle",
                    "full-screen",
                  ]}
                />
                <StyledFormHelperText error={!!formik.touched["content"] && !!formik.errors["content"]}>
                  {formik.touched["content"] && formik.errors["content"]}
                </StyledFormHelperText>
              </Grid>
            </StyledGrid>
          </form>

          <form
            noValidate
            onSubmit={formik.handleSubmit}
            onKeyDown={(e: React.KeyboardEvent<HTMLElement>) => {
              const element = e.target as HTMLElement;

              if (element.nodeName !== "TEXTAREA" && e.key === "Enter") {
                e.preventDefault();
              }
            }}
          >
            <StyledGrid container xs={12}>
              <StyledGridItem item xs={7}>
                <TextFieldWrapper
                  size="small"
                  fullWidth
                  onChange={formik.handleChange}
                  label={INPUT_LABEL.META_TITLE}
                  name="metaTitle"
                  value={formik.values.metaTitle}
                  helperText={formik.touched["metaTitle"] && formik.errors["metaTitle"]}
                />
              </StyledGridItem>
              <Grid container xs={7}>
                <StyledGridItem item xs={12}>
                  <TextFieldWrapper
                    size="small"
                    fullWidth
                    onChange={formik.handleChange}
                    label={INPUT_LABEL.META_DESCRIPTION}
                    name="metaDescription"
                    value={formik.values.metaDescription}
                    helperText={formik.touched["metaDescription"] && formik.errors["metaDescription"]}
                  />
                </StyledGridItem>
              </Grid>
              <StyledGridItem item xs={7}>
                <TextFieldWrapper
                  size="small"
                  fullWidth
                  multiline
                  rows={1}
                  inputProps={{
                    style: { minHeight: 90, width: "100%" },
                  }}
                  onChange={formik.handleChange}
                  label={INPUT_LABEL.META_KEYWORD}
                  name="metaKeywords"
                  value={formik.values.metaKeywords}
                  helperText={formik.touched["metaKeywords"] && formik.errors["metaKeywords"]}
                />
              </StyledGridItem>
              <StyledGridBottom container xs={12}>
                <Grid item>
                  <ButtonsWrapper>
                    <Grid item xs={5}>
                      <CancelButton onClick={cancelHandler} text={BTN_TITLE.CANCEL} type="button" />
                    </Grid>
                    <SaveButtonWrapper item xs={5}>
                      <AdminMainButton text={BTN_TITLE.SAVE} disabled={isEdit ? !hasChanges : false} />
                    </SaveButtonWrapper>
                  </ButtonsWrapper>
                </Grid>
              </StyledGridBottom>
            </StyledGrid>
          </form>
          {notificationIsOpen && <Notification isOpen={notificationIsOpen} onClose={hadleCloseNotification} />}
        </FormWrapper>
      ) : (
        <h1>loading</h1>
      )}
    </Container>
  );
};

export default Content;
