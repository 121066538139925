import { createSlice } from "@reduxjs/toolkit";
import { IStoreStatuses, STATUS_ENUM } from "../../types/statuses";
import { StatusGenerator } from "../../utils/StatusGenerator";
import {
  createContentThunk,
  createContentTranslationThunk,
  editContentThunk,
  editContentTranslationThunk,
  getAllContentsByProjectThunk,
  getContentByIdThunk,
  getContentTranslationByIdThunk,
} from "./thunks";
import { LoadingResultsT } from "../../helpers/constants";
import { Content } from "../../types/contents";

interface IState {
  contents: Content[] | null;
  selectedContent: Content | null;
  path: string | null;
  loading: LoadingResultsT.IDLE | LoadingResultsT.PENDING | LoadingResultsT.SUCCEEDED | LoadingResultsT.FAILED;
  statuses: IStoreStatuses;
}
const initialState: IState = {
  contents: [],
  selectedContent: null,
  path: null,
  loading: LoadingResultsT.IDLE,
  statuses: StatusGenerator.generateStatuses([
    getAllContentsByProjectThunk.typePrefix,
    createContentThunk.typePrefix,
    getAllContentsByProjectThunk.typePrefix,
    editContentThunk.typePrefix,
    getContentTranslationByIdThunk.typePrefix,
    editContentTranslationThunk.typePrefix,
    createContentTranslationThunk.typePrefix,
  ]),
};
const contentSlice = createSlice({
  name: "contents",
  initialState: initialState,
  reducers: {
    reset: () => initialState,
    setContent: (state, action) => {
      state.contents = action.payload.data ?? null;
    },
    setSelectedContent: (state, action) => {
      state.selectedContent = action.payload.data ?? null;
    },
    resetSelectedContent: (state) => {
      state.selectedContent = initialState.selectedContent;
    },
    resetStatus: (state) => {
      state.statuses = initialState.statuses;
    },
    resetPath: (state) => {
      state.path = initialState.path;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(createContentThunk.pending, (state) => {
        state.statuses[createContentThunk.typePrefix] = StatusGenerator.setStatus(STATUS_ENUM.PENDING);
        state.loading = LoadingResultsT.PENDING;
      })
      .addCase(createContentThunk.rejected, (state, { payload }) => {
        state.statuses[createContentThunk.typePrefix] = StatusGenerator.setStatus(
          STATUS_ENUM.REJECTED,
          payload as string
        );
        state.loading = LoadingResultsT.FAILED;
      })
      .addCase(createContentThunk.fulfilled, (state, { payload }) => {
        state.statuses[createContentThunk.typePrefix] = StatusGenerator.setStatus(STATUS_ENUM.FULFILLED);
        state.selectedContent = payload;
        state.loading = LoadingResultsT.SUCCEEDED;
      })
      .addCase(getAllContentsByProjectThunk.pending, (state) => {
        state.statuses[getAllContentsByProjectThunk.typePrefix] = StatusGenerator.setStatus(STATUS_ENUM.PENDING);
        state.loading = LoadingResultsT.IDLE;
      })
      .addCase(getAllContentsByProjectThunk.rejected, (state, { error }) => {
        state.statuses[getAllContentsByProjectThunk.typePrefix] = StatusGenerator.setStatus(
          STATUS_ENUM.REJECTED,
          error.message
        );
      })
      .addCase(getAllContentsByProjectThunk.fulfilled, (state, { payload }) => {
        state.statuses[getAllContentsByProjectThunk.typePrefix] = StatusGenerator.setStatus(STATUS_ENUM.FULFILLED);
        state.contents = payload;
      })
      .addCase(getContentByIdThunk.pending, (state) => {
        state.statuses[getContentByIdThunk.typePrefix] = StatusGenerator.setStatus(STATUS_ENUM.PENDING);
      })
      .addCase(getContentByIdThunk.rejected, (state, { error }) => {
        state.statuses[getContentByIdThunk.typePrefix] = StatusGenerator.setStatus(STATUS_ENUM.REJECTED, error.message);
      })
      .addCase(getContentByIdThunk.fulfilled, (state, { payload }) => {
        state.statuses[getContentByIdThunk.typePrefix] = StatusGenerator.setStatus(STATUS_ENUM.FULFILLED);
        state.selectedContent = payload;
        state.path = payload.path;
      })
      .addCase(editContentThunk.pending, (state) => {
        state.statuses[editContentThunk.typePrefix] = StatusGenerator.setStatus(STATUS_ENUM.PENDING);
        state.loading = LoadingResultsT.PENDING;
      })
      .addCase(editContentThunk.rejected, (state, { payload }) => {
        state.statuses[editContentThunk.typePrefix] = StatusGenerator.setStatus(
          STATUS_ENUM.REJECTED,
          payload as string
        );
        state.loading = LoadingResultsT.FAILED;
      })
      .addCase(editContentThunk.fulfilled, (state, { payload }) => {
        state.statuses[editContentThunk.typePrefix] = StatusGenerator.setStatus(STATUS_ENUM.FULFILLED);
        state.selectedContent = payload?.data;
      })
      .addCase(getContentTranslationByIdThunk.pending, (state) => {
        state.statuses[getContentTranslationByIdThunk.typePrefix] = StatusGenerator.setStatus(STATUS_ENUM.PENDING);
      })
      .addCase(getContentTranslationByIdThunk.rejected, (state, { error }) => {
        state.statuses[getContentTranslationByIdThunk.typePrefix] = StatusGenerator.setStatus(
          STATUS_ENUM.REJECTED,
          error.message
        );
      })
      .addCase(getContentTranslationByIdThunk.fulfilled, (state, { payload }) => {
        state.statuses[getContentTranslationByIdThunk.typePrefix] = StatusGenerator.setStatus(STATUS_ENUM.FULFILLED);
        state.selectedContent = payload;
      })
      .addCase(editContentTranslationThunk.pending, (state) => {
        state.statuses[editContentTranslationThunk.typePrefix] = StatusGenerator.setStatus(STATUS_ENUM.PENDING);
        state.loading = LoadingResultsT.PENDING;
      })
      .addCase(editContentTranslationThunk.rejected, (state, { payload }) => {
        state.statuses[editContentTranslationThunk.typePrefix] = StatusGenerator.setStatus(
          STATUS_ENUM.REJECTED,
          payload as string
        );
        state.loading = LoadingResultsT.FAILED;
      })
      .addCase(editContentTranslationThunk.fulfilled, (state, { payload }) => {
        state.statuses[editContentTranslationThunk.typePrefix] = StatusGenerator.setStatus(STATUS_ENUM.FULFILLED);
        state.selectedContent = payload?.data;
      })
      .addCase(createContentTranslationThunk.pending, (state) => {
        state.statuses[createContentTranslationThunk.typePrefix] = StatusGenerator.setStatus(STATUS_ENUM.PENDING);
        state.loading = LoadingResultsT.PENDING;
      })
      .addCase(createContentTranslationThunk.rejected, (state, { payload }) => {
        state.statuses[createContentTranslationThunk.typePrefix] = StatusGenerator.setStatus(
          STATUS_ENUM.REJECTED,
          payload as string
        );
        state.loading = LoadingResultsT.FAILED;
      })
      .addCase(createContentTranslationThunk.fulfilled, (state, { payload }) => {
        state.statuses[createContentTranslationThunk.typePrefix] = StatusGenerator.setStatus(STATUS_ENUM.FULFILLED);
        state.selectedContent = payload;
      }),
});

export default contentSlice;
