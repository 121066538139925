import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { LongMenuConfig } from "../../helpers/constants";

const ITEM_HEIGHT = 48;

export type Props = {
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedUserId: React.Dispatch<React.SetStateAction<any>>;
  userId: string;
  isAdmin: boolean;
};
export default function LongMenu(props: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleResetPassword = () => {
    handleClose();
    props.setOpenModal(true);
    props.setSelectedUserId(props.userId);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
            borderRadius: 12,
          },
        }}
      >
        <MenuItem key={LongMenuConfig.EDIT} onClick={handleClose}>
          {LongMenuConfig.EDIT}
        </MenuItem>
        <MenuItem
          key={LongMenuConfig.RESET_PASSWORD}
          disabled={!props.isAdmin}
          onClick={handleResetPassword}
        >
          {LongMenuConfig.RESET_PASSWORD}
        </MenuItem>
        <MenuItem key={LongMenuConfig.DEACTIVATE} onClick={handleClose}>
          {LongMenuConfig.DEACTIVATE}
        </MenuItem>
      </Menu>
    </div>
  );
}
