import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../config/api";
import { API_ROUTES } from "../../config/apiRoutes";
import { Content, ContentTranslationRequest, CreateContentQuery } from "../../types/contents";
import { initialValuesEditI } from "../../pages/Content/config";

/**
 * @description Dispatch this thunk to create content for project
 */
export const createContentThunk = createAsyncThunk<Content, { id: string; bodyData: CreateContentQuery }>(
  "contents/createContentThunk",
  async ({ id, bodyData }: any, { rejectWithValue }) => {
    try {
      const { data } = await API.post(API_ROUTES.CONTENTS.CREATE(id), {
        ...bodyData,
      });

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.error);
    }
  }
);

/**
 * @description Dispatch this thunk to get list of project contents
 */
export const getAllContentsByProjectThunk = createAsyncThunk(
  "contents/getAllContentsByProjectThunk",
  async ({ id }: any, { rejectWithValue }) => {
    try {
      const { data } = await API.get(API_ROUTES.CONTENTS.ALL_CONTENTS_BY_PROJECTS(id));

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/**
 * @description Dispatch this thunk to get content by id
 * @param {string} id - String id to find current content
 */
export const getContentByIdThunk = createAsyncThunk<Content, string>(
  "contents/getContentByIdThunk",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await API.get(API_ROUTES.CONTENTS.GET_BY_ID(id));

      return data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

/**
 * @description Dispatch this thunk to edit content by id
 */
export const editContentThunk = createAsyncThunk(
  "contents/editContentThunk",
  async ({ id, ...value }: initialValuesEditI, { rejectWithValue }) => {
    try {
      const contents = await API.put(API_ROUTES.CONTENTS.UPDATE_CONTENT(id), value);
      return contents;
    } catch (error: any) {
      return rejectWithValue(error.response.data.error);
    }
  }
);

/**
 * @description Dispatch this thunk to get content translation by contentId
 */
export const getContentTranslationByIdThunk = createAsyncThunk<Content, ContentTranslationRequest>(
  "contents/getContentTranslationByIdThunk",
  async ({ contentId, languageCode }: any, { rejectWithValue }) => {
    try {
      const { data } = await API.get(API_ROUTES.CONTENTS_TRANSLATION.GET_BY_ID(contentId, languageCode));

      return data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

/**
 * @description Dispatch this thunk to create content translation for contentId
 */
export const createContentTranslationThunk = createAsyncThunk(
  "contents/createContentTranslationThunk",
  async ({ contentId, languageCode, content, metaTitle, metaKeywords, metaDescription }: any, { rejectWithValue }) => {
    try {
      const { data } = await API.post(API_ROUTES.CONTENTS_TRANSLATION.CREATE(contentId, languageCode), {
        content,
        metaTitle,
        metaKeywords,
        metaDescription,
      });

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.error);
    }
  }
);

/**
 * @description Dispatch this thunk to edit content translation by contentId
 */
export const editContentTranslationThunk = createAsyncThunk(
  "contents/editContentTranslationThunk",
  async ({ contentId, languageCode, content, metaTitle, metaKeywords, metaDescription }: any, { rejectWithValue }) => {
    try {
      const contents = await API.put(API_ROUTES.CONTENTS_TRANSLATION.UPDATE_CONTENT(contentId, languageCode), {
        content,
        metaTitle,
        metaKeywords,
        metaDescription,
      });

      return contents;
    } catch (error: any) {
      return rejectWithValue(error.response.data.error);
    }
  }
);
